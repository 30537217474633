import React, { useState, useEffect } from 'react';
import './wallet_menu.css';

const WalletMenu = ({ items, onItemClick, onClose }) => {
  useEffect(() => {
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
    return () => {
      overlay.style.display = 'none';
    };
  }, []);

  return (
    <>
      <div id="overlay" className="overlay" onClick={onClose}></div>
      <div className="menuWallet">
        {items.map((item, index) => (
          <div key={index} className="menuWallet-item" onClick={() => onItemClick(item, index)}>
            <img src={item.icon} alt={`${item.text} icon`} className="menuWallet-icon" />
            <span className="menuWallet-text">{item.text}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default WalletMenu;