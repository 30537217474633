export const tokenNames: Record<number, string> = {
  1: "10MM",
  2: "11MM",
  3: "15MM",
  4: "16MM",
  5: "18MM",
  6: "19MM",
  7: "20MM",
  8: "21MM",
};


