import React from 'react';
import { mempoolLink } from '../config_info';
import { shortenString } from '../utils';

interface ExternalLinkProps {
  hash: string;
  baseUrl?: string;
  className?: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ hash, baseUrl = mempoolLink, className = "hash-link" }) => {
  const href = `${baseUrl}${hash}`;
  // console.log("Generated href:", href); // 调试输出 href
  let displayText =shortenString(hash.replace(/^(address\/|tx\/|block\/)/, ''));

  return (
    <a
      href={href}
      target="_blank"
      className={className}
      style={{color:"inherit",position: 'relative', zIndex: 10 }} // 确保链接层级较高

    >
      {displayText}
    </a>
  );
};

export default ExternalLink;