let network: string, okxNetwork: any;
let baseURL: string;
let mempoolUrl: string;
let mempoolLink: string;


const configureNetwork = (production: boolean, production_server: boolean) => {
    if (production) {
        network = "livenet";
        if (window.okxwallet && window.okxwallet.bitcoin) {
            okxNetwork = window.okxwallet.bitcoin;
        } else {
            console.error('okxwallet is not installed!');
        }
        baseURL = "";
        mempoolUrl = "https://mempool.space/api";
        mempoolLink= "https://mempool.space/";
    } else {
        network = "testnet";
        if (window.okxwallet && window.okxwallet.bitcoinTestnet) {
            okxNetwork = window.okxwallet.bitcoinTestnet;
        } else {
            console.error('okxwallet is not installed!');
        }
        if (production_server) {
            baseURL = ""
        } else {
            // baseURL = "http://localhost:5001"
            baseURL = "http://192.168.1.6:5001"//手机局域网需要访问时
        }
        mempoolUrl = "https://mempool.space/testnet/api"
        mempoolLink= "https://mempool.space/testnet/";

        /**test**/
        // baseURL = "http://192.168.1.7:5001"
        // network = "livenet"
        // mempoolUrl = "https://mempool.space/api"
    }
};

// 导出配置函数
export { network, okxNetwork, baseURL, mempoolUrl,mempoolLink, configureNetwork };