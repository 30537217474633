import { useContext, forwardRef, useImperativeHandle } from 'react';
import { WalletContext } from '../wallet/WalletContext';
import { shortenString } from '../utils';


declare global {
  interface Window {
    unisat?: any;
  }
}
interface OkxProps {
  initData: () => void;
  handleLogout: () => void;
}
interface UnisatRefType {
  connectUnisatWallet: (network: string) => void;
}

const Unisat = forwardRef<UnisatRefType, OkxProps>((props, ref) => {
  const {
    walletAddress,
    setWalletAddress,
    setWalletConnected,
    setBtnConnectText
  } = useContext(WalletContext);

  const handleAccountsChanged = (accounts: Array<string>) => {
    console.log("handleAccountsChanged:" + accounts[0])
    if (accounts.length === 0) {
      // 用户可能已断开连接
      props.handleLogout();
    } else {
      props.initData();  // 调用从 App 传递过来的 initData 方法
      setWalletAddress(accounts[0]);
      setWalletConnected(true);
      setBtnConnectText(shortenString(accounts[0]));
    }
  };
  let isListenerAdded = false; // 用于防止重复添加事件监听

  const connectUnisatWallet = async (network: string) => {
    if (!window.unisat) {
      // setConnectWalletResponse('UniSat Wallet is not installed!');
      setBtnConnectText('No UniSat Wallet')
      return;
    }
    try {
      await window.unisat.switchNetwork(network);
      const accounts = await window.unisat.requestAccounts();
      setWalletAddress(accounts[0]);
      setWalletConnected(true);
      setBtnConnectText(shortenString(accounts[0]))

      // 只添加一次账户更改事件监听
      if (!isListenerAdded) {
        window.unisat.on('accountsChanged', handleAccountsChanged);
        isListenerAdded = true;  // 标记事件已绑定
      }

    } catch (error) {
      console.error('Failed connectUnisatWallet:', error);
      setBtnConnectText('Failed connectUnisatWallet')
    }
  };

  useImperativeHandle(ref, () => ({
    connectUnisatWallet,
  }));

  return null; // Unisat 组件不渲染任何内容

});

export default Unisat;
