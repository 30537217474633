import React, { useContext, forwardRef, useImperativeHandle } from 'react';
import { WalletContext } from '../wallet/WalletContext';
import { shortenString } from '../utils';

declare global {
  interface Window {
    okxwallet?: any;
    bitcoinTestnet?: any;   // 测试网络
  }
}
interface OkxProps {
  initData: () => void;
  handleLogout: () => void;
}

interface OkxRefType {
  connectOkxWallet: (okxNetwork: any) => void;
}



const Okx = forwardRef<OkxRefType, OkxProps>((props, ref) => {
  const {
    walletAddress,
    setWalletAddress,
    setWalletConnected,
    setBtnConnectText
  } = useContext(WalletContext);

  const handleAccountsChanged = (accounts: Array<string>) => {
    props.initData();  // 调用从 App 传递过来的 initData 方法
    if (accounts && accounts.length !== 0) {
      setWalletAddress(accounts[0]);
      setWalletConnected(true);
      setBtnConnectText(shortenString(accounts[0]));
    }
  };

  const handleDisconnect = (error: any) => {
    props.handleLogout();
  };
  let isListenerAdded = false; // 用于防止重复添加事件监听

  const connectOkxWallet = async (okxNetwork: any) => {
    if (!window.okxwallet) {
      setBtnConnectText('No Okx Wallet')
      return;
    }

    try {

      const result = await okxNetwork.connect()
      setWalletAddress(result.address);
      setWalletConnected(true);
      setBtnConnectText(shortenString(result.address));

      // 连接钱包后再监听账户更改事件
      // 只添加一次账户更改事件监听
      if (!isListenerAdded) {
        okxNetwork.on('accountsChanged', handleAccountsChanged);
        okxNetwork.on('disconnect', handleDisconnect);
        isListenerAdded = true;  // 标记事件已绑定

      }



    } catch (error) {
      console.error('Failed connectOkxWallet', error);
      setBtnConnectText('Failed connectOkxWallet');
    }
  };

  useImperativeHandle(ref, () => ({
    connectOkxWallet,
  }));


  return null; // Okx 组件不渲染任何内容

});

export default Okx;
