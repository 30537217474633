import React from 'react';
import { MintInfo } from '../model/MintInfo';
import './MintRecords.css';
import ExternalLink from '../utils/ExternalLink';


interface MintRecordsProps {
  mintRecords: MintInfo[] | null | undefined;
}

const MintRecords: React.FC<MintRecordsProps> = ({ mintRecords }) => {
  if (!mintRecords || mintRecords.length === 0) {
    return null; // 当 mintRecords 为空时，不渲染任何内容
  }
  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>BlockNum</th>
            <th>Fee(btc)</th>
            <th>Status</th>
            <th>Hash</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {mintRecords.map((info: any) => (
            <tr key={info.id}>
              {/* <td>{info.minerAddress}</td> */}
              <td>{info.blockNum !== 0 ? info.blockNum : "\\"}</td>
              <td>{(info.txFee / 100000000).toFixed(5)}</td>
              <td>{info.status == 0 ? "Unconfirmed" : "Confirmed"}</td>
              {/* <td>{info.txHash}</td> */}
              <td className="hash-cell">
                <ExternalLink hash={"tx/" + info.txHash} />
              </td>
              <td>{new Date(info.createTime).toLocaleString()}</td>
              {/**toLocaleString 可以根据当前用户的区域设置将日期格式化为本地时间格式。**/}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MintRecords;