import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './POWGet.css';
import Menu from '../menu/Menu';
import Record from '../record/Record';
import { shortenString, formattedDate } from '../utils';
import { TokenInfo, AvailToken } from '../model/token';
import { production, production_server } from '../config';
import IntroParvalue from '../intro_parvalue/introParvalue';
import WalletMenu from '../wallet/wallet_menu';
import { WalletContext } from '../wallet/WalletContext';
import Okx from '../okx_web3/okx';
import Unisat from '../unisat/unisat';
import { configureNetwork, network,okxNetwork, baseURL, mempoolUrl } from '../config_info';

configureNetwork(production, production_server);

const alerIcon = ['success', 'error', 'warning', 'info', 'question']
const notEligible_tip = "You are not eligible"
const claim_tip = "You can click and claim the highlighted one"



const addFee = 0
const feeRateSend = 2//user发送btc费率，正式环境不用设置

declare global {
  interface Window {
    unisat?: any;
    okxwallet?: any;  // 添加 okxwallet 到 Window 接口
    bitcoinTestnet?: any;   // 测试网络
  }
}
interface OkxRefType {
  connectOkxWallet: (okxNetwork: any) => void;
}
interface UnisatRefType {
  connectUnisatWallet: (network: string) => void;
}


const POWGet: React.FC = () => {
  // const walletContext = useContext(WalletContext);
  const { btnConnectText, setBtnConnectText, walletConnected, setWalletConnected, walletAddress, setWalletAddress } = useContext(WalletContext);
  const [selectedWallet, setSelectedWallet] = useState<number>(-1);//0:unisat 1:okx web3


  const [tokens, setTokens] = useState<TokenInfo[]>();
  const [checkEligibleResponse, setCheckEligibleResponse] = useState("");

  const [transactionCreated, setTransactionCreated] = useState(false);
  const [estimateFee, setEstimateFee] = useState(null);
  const [availTokens, setAvailTokens] = useState<AvailToken[]>([]);
  const [selectedAvailToken, setSelectedAvailToken] = useState<AvailToken | null>();
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(-1);


  const [showMenu, setShowMenu] = useState(false);
  const items = ["My Record", "Disconnect"];
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const menuRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);


  const columns = ["tick", "type", "quantity", "states", "txHash", "date"]
  const [myCliamList, setMyCliamList] = useState<string[][]>([]);
  const [cliamList, setCliamList] = useState<string[][]>([]);

  const marqueeRef = useRef<HTMLDivElement>(null);

  const [showWalletMenu, setWalletMenu] = useState(false);

  const walletMenuItems = [
    { icon: '/parvalue/unisat.svg', text: 'Unisat' },
    { icon: '/parvalue/okx_wallet.svg', text: 'Okx web3' }
  ];

  const okxRef = useRef<OkxRefType>(null);
  const unisatRef = useRef<UnisatRefType>(null);



  const showSpinner = () => {
    document.getElementById('spinner-overlay')!.style.visibility = 'visible';
  };

  const hideSpinner = () => {
    document.getElementById('spinner-overlay')!.style.visibility = 'hidden';
  };


  useEffect(() => {
    // 在组件挂载时请求数据
    getTokens();
    getClaimList(null)
  }, []); // 空数组作为依赖项，确保只在组件挂载时调用一次


  const handleLogout = () => {
    setWalletConnected(false);
    setWalletAddress(null);
    setBtnConnectText('Connect');
    initData()
  };

  /**
   * 用户切换钱包后清除数据
   * **/
  const initData = () => {
    setAvailTokens([])
    setEstimateFee(null)
    setTransactionCreated(false);
    setSelectedAvailToken(null)
    setSelectedButtonIndex(-1)
    setCheckEligibleResponse("")
  };


  /**
   * getTokens
   * result:tokens
   * **/
  const getTokens = async () => {
    try {
      const result = await axios.get(baseURL + '/api/tokens/getTokens');
      setTokens(result.data);
      console.log('fetch getTokens:', result.data);
    } catch (error) {
      console.error('Failed to fetch getTokens:', error);
    }
  };

  /**
     * user checkEligible
     * result:tokens
     * **/
  const checkEligible = async () => {
    if (walletAddress == null) {
      setCheckEligibleResponse(' Wallet is not connected!');
      return
    }
    setTransactionCreated(false);
    try {
      const result = await axios.get(baseURL + '/api/infos/checkEligible/', {
        params: {
          walletAddress: walletAddress,
        }
      });
      if (result.data == null || result.data.length <= 0) {
        setAvailTokens([])
        setCheckEligibleResponse(notEligible_tip);
        return
      }
      setAvailTokens(result.data);
      setCheckEligibleResponse(claim_tip)
      console.log('fetch checkEligible:', result.data);
    } catch (error) {
      console.error('Failed to fetch checkEligible:', error);
      setCheckEligibleResponse('Failed to fetch checkEligible');
    }
  };
  const isTokenAvailable = (tokenId: number): boolean => {
    return availTokens.some((availToken: AvailToken) => availToken.tokenInfo.some(token => token.id === tokenId));
  };

  /**
   * getEstimateFee for user
   * **/
  const getEstimateFee = async (selectedTokenType: number) => {
    showSpinner()
    try {
      const params = {
        id: selectedAvailToken?.tokenInfo[selectedTokenType].id,
        blockNum: selectedAvailToken?.blockNum,
        minerAddress: walletAddress

      };
      const result = await axios.post(baseURL + '/api/infos/getEstimateFee', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.data.estimateFee > 0) {
        setEstimateFee(result.data.estimateFee);
        console.log('fetch getEstimateFee:', result.data.estimateFee);
        await createTransaction(selectedTokenType, result.data.estimateFee, result.data.getPayAddress)
      }
    } catch (error) {
      console.error('Failed to fetch getEstimateFee:', error);
      // setResponse('Failed to fetch getEstimateFee');
    }
    finally {
      hideSpinner()
    }
  };
  // useEffect(() => {
  //   if (estimateFee !== null) {
  //     createTransaction();
  //   }
  // }, [estimateFee]);

  async function createTransaction(selectedTokenType: any, satoshis: any, getPayAddress: any) {
    try {
      // const options = { feeRate: feeRateSend, nSequence: 0xffffffff };  // (optional)the network fee rate
      let txid
      if (selectedWallet == 0) {
        txid = await window.unisat.sendBitcoin(getPayAddress, satoshis);
      } else if (selectedWallet == 1) {
        txid = await okxNetwork.sendBitcoin(getPayAddress, satoshis);
      }
      console.log('Transaction created:', txid);
      if (txid != null) {
        showSpinner()
        setTransactionCreated(true);
        const selectedToken = selectedAvailToken?.tokenInfo[selectedTokenType];
        await commitTrans(selectedToken?.id, walletAddress, selectedAvailToken?.blockNum, getPayAddress, satoshis, txid)
      }
    } catch (error) {
      console.error('Failed to create transaction:', error);
      // setResponse('Transaction Failed');
    }
    finally {
      hideSpinner()
    }
  };


  /**
  * submitTrans
  * **/
  async function commitTrans(tokenID: any, minerAddress: any, blockNum: any, getPayAddress: any, payGasFee: any, payHash: any) {
    try {
      const params = {
        tokenID: tokenID,
        minerAddress: minerAddress,
        blockNum: blockNum,
        getPayAddress: getPayAddress,
        payGasFee: payGasFee,
        payHash: payHash,
      };
      console.log("commitTrans params:" + params)
      const result = await axios.post(baseURL + '/api/claim/insertInfo', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200) {
        console.log('fetch commitTrans:', result.data);
        setAvailTokens([])
        checkEligible()//刷新可领取的tokens 
        showCustomAlert("Tip", "Your claim has been submitted! ", alerIcon[0])
      }
    } catch (error) {
      console.error('Failed to fetch commitTrans:', error);
      // setResponse('Failed to fetch commitTrans');
    }
  };


  /**
   * getClaimList
   * **/
  const getClaimList = async (walletAddress: any) => {
    try {
      const result = await axios.get(baseURL + '/api/claim/searchClaimInfos', {
        params: {
          walletAddress: walletAddress,
        }
      });
      if (walletAddress == null) {//all
        const recordData = result.data.map((item: any) => {
          return [shortenString(item.minerAddress), item.tokenName, formattedDate(item.timestamp)];
        });
        setCliamList(recordData)
      } else {//owner
        if (result.data == null || result.data.length <= 0) {
          return
        }
        const recordData = result.data.map((item: any) => {
          let stateLabel;
          if (item.states < 3) {
            stateLabel = 'Processing';
          } else {
            stateLabel = 'Finished';
          }
          return [item.tokenName, item.tokenType, item.quantity, stateLabel, item.transHash, formattedDate(item.timestamp)];
        });
        setMyCliamList(recordData)
      }

      console.log('fetch getClaimList:', result.data);
    } catch (error) {
      console.error('Failed to fetch getClaimList:', error);
      // setResponse('Failed to fetch testAirdrop');
    }
  };


  /**
  * test
  * **/
  const testAirdrop = async () => {
    try {
      const params = {
        tokenID: 8,
        tokenName: "21MM",
        minerAddress: walletAddress,
        blockNum: 861821,
        txIndex: 0,
        quantity: 1,
        payGasFee: 0,
        actualGasFee: 0,
        states: 0,
        payHash: "eece0b91bbdb3aebfea5498ebd5afc886f3ed4460256de598a88ab147e0c0ba9",//
        inscriHash: "",
        transHash: "",
      }
      // const result = await axios.post(baseURL + '/api/claim/test/insertInfo', params, {
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // });
      // console.log('fetch testAirdrop:', result.data);
    } catch (error) {
      console.error('Failed to fetch testAirdrop:', error);
      // setResponse('Failed to fetch testAirdrop');
    }
  };

  function showCustomAlert(title: any, text: any, icon: any) {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: false,
      scrollbarPadding: false, // 禁用滚动条
      allowOutsideClick: false,  // 禁止点击空白处取消
      confirmButtonText: 'ok',
      cancelButtonText: 'close',
      // customClass: {
      //   popup: 'custom-swal-popup',  // 自定义弹窗样式
      //   title: 'custom-swal-title'  // 自定义标题样式
      //   // content: 'custom-swal-content'  // 自定义内容样式
      // }
    }).then((result) => {
      // if (result.isConfirmed) {
      //   Swal.fire('已确认！', '你的操作已经执行。', 'success');
      // } else if (result.dismiss === Swal.DismissReason.cancel) {
      //   Swal.fire('已取消', '你的操作已取消 :)', 'error');
      // }
    });
  }

  /*******************menu*************************/
  const menuItemClick = (item: string, index: number) => {
    console.log(`Clicked on ${item}`);
    setShowMenu(false); // Hide menu after clicking an item
    if (index === 0) {
      getClaimList(walletAddress)
    } else if (index === 1) {
      handleLogout()
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && textRef.current && !textRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);
  /*******************menu end*************************/

  // const getAvailTokenByTokenId = (tokenId: number): AvailToken | undefined => {
  //   return availTokens.find(availToken =>
  //     availToken.tokenInfo.some(token => token.id === tokenId)
  //   );
  // };

  /**
   * 因为一个区块可能有多个可领取的面值，所以要筛选出与选中的token一样面值的数据
   * **/
  const getAvailTokenByTokenId = (tokenId: number): AvailToken | null => {
    // 首先找到目标 token
    const targetToken = availTokens
      .flatMap(availToken => availToken.tokenInfo)
      .find(token => token.id === tokenId);

    if (!targetToken) {
      return null; // 如果没有找到目标 token，则返回空数组
    }

    const targetTokenName = targetToken.tokenName.trim(); // 获取目标 token 的名称并去除多余空格

    // 筛选出 tokenName 包含 targetTokenName 的 AvailToken
    const filteredAvailTokens = availTokens.map(availToken => {
      const matchingTokens = availToken.tokenInfo.filter(token =>
        token.tokenName.trim() === targetTokenName ||
        token.tokenName.trim().includes(targetTokenName)
      );

      if (matchingTokens.length > 0) {
        return {
          ...availToken,
          tokenInfo: matchingTokens
        };
      }
      return null;
    }).filter(availToken => availToken !== null);

    return filteredAvailTokens[0];
  };

  const [triggerEffect, setTriggerEffect] = useState(false);
  const btnAvailTokenClick = (id: number, index: number) => {
    console.log("id:" + id + "-index:" + index)
    setSelectedAvailToken(getAvailTokenByTokenId(id));
    setSelectedButtonIndex(index)
    setTriggerEffect(prev => !prev); // 切换 triggerEffect 的值
  };
  useEffect(() => {
    if (selectedAvailToken !== null && selectedButtonIndex !== -1) {
      showRadioOptions();
    }
  }, [selectedAvailToken, selectedButtonIndex, triggerEffect]);

  const showRadioOptions = () => {
    const tokens = selectedAvailToken?.tokenInfo
    const inputOptions = tokens?.reduce((options, token, index) => {
      options[index] = token.tokenType;
      return options;
    }, {} as { [key: number]: string });
    Swal.fire({
      title: 'Select the type of inscription you want to claim',
      input: 'radio',
      scrollbarPadding: false, // 禁用滚动条
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to choose something!';
        }
        return null;
      },
      preConfirm: (value) => {
        console.log(parseInt(value))
        //开始提交claim
        getEstimateFee(parseInt(value))
      }
    });
  };

  // Close record
  const handleCloseRecord = () => {
    setMyCliamList([])
  };

  useEffect(() => {
    const marqueeContent = marqueeRef.current;

    if (marqueeContent) {
      // 重置动画以确保在内容变化后重新启动
      marqueeContent.style.animation = 'none';
      void marqueeContent.offsetHeight; // 触发重绘
      marqueeContent.style.animation = 'marquee 30s linear infinite';
    }
  }, [cliamList]); // 依赖项为 claimList，当其变化时重新运行


  const walletMenuItemClick = (item: { icon: string; text: string }, index: number) => {
    console.log(`Clicked on ${item.text} at index ${index}`);
    setWalletMenu(false);
    setSelectedWallet(index)
    if (index === 0) {
      if (unisatRef.current) {
        unisatRef.current.connectUnisatWallet(network);
      }
    } else if (index === 1) {
      if (okxRef.current) {
        okxRef.current.connectOkxWallet(okxNetwork);
      }
    }
  };

  const connectWalletClick = () => {
    if (walletConnected) {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect();
        setMenuPosition({ top: rect.bottom + 10, left: rect.left - 80 });
        setShowMenu(!showMenu);
      }
      return
    }
    setWalletMenu(true);

  };
  const walletMenuClose = () => {
    setWalletMenu(false);
  };
  return (
    <div className="container">
      <Okx ref={okxRef} initData={initData} handleLogout={handleLogout} />
      <Unisat ref={unisatRef} initData={initData} handleLogout={handleLogout} />

      {showWalletMenu && (
        <WalletMenu items={walletMenuItems} onItemClick={walletMenuItemClick} onClose={walletMenuClose} />
      )}
      {/**cliamList marquee**/}
      {cliamList && cliamList.length > 0 && (
        <div className="marquee">
          <div className="marquee-content" ref={marqueeRef}>
            {cliamList.map((row, index) => (
              <span key={index} className="marquee-item">
                {`${row[0]} claimed ${row[1]}  ${row[2]}`}
              </span>
            ))}
          </div>
        </div>
      )}
      {/**cliamList marquee  end**/}
      <div className='title'>
      <p className="title-text text-with-shadow">POW Claim</p>
      <div ref={textRef} onClick={connectWalletClick} className="button-style-connect">
          <span>{btnConnectText}</span>
          {walletConnected && <i className="fas fa-chevron-down icon"></i>}
        </div>
      </div>
      {showMenu && (
        <div ref={menuRef} style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left }}>
          <Menu items={items} onItemClick={menuItemClick} />
        </div>
      )}
      <div className="button-container">

        {tokens != null && tokens.length > 0 && (
          <div className="token-buttons-container">
            {tokens.map((token: any, index: number) => {
              return (
                <div key={index}>
                  <button
                    key={index}
                    onClick={() => btnAvailTokenClick(token.id, index)}
                    // disabled={transactionCreated}
                    disabled={!walletConnected || transactionCreated || availTokens === null || availTokens.length === 0 || (availTokens !== null && availTokens.length > 0 && !isTokenAvailable(token.id))}
                    className={`token-button ${selectedButtonIndex === index ? 'selected' : ''}`}
                    style={{ backgroundImage: `url(/parvalue/${token.tokenName.replace(/\s+/g, '')}.png)` }}
                  >
                  </button>
                  {/* <span className="token-type">{token.tokenType + ""}</span> */}
                </div>
              );
            })}
          </div>
        )}
        <span className="response">{checkEligibleResponse}</span>
        <button className={`button-style-normal ${!walletConnected || transactionCreated ? 'hidden' : ''}`} onClick={checkEligible} disabled={availTokens != null && availTokens.length > 0}>check eligible</button>
      </div>

      <div id="spinner-overlay" className="spinner-overlay">
        <div className="spinner"></div>
      </div>

      <Record show={myCliamList.length > 0} onClose={handleCloseRecord} data={myCliamList} columns={columns} />


      <IntroParvalue />

    </div>
  );
};


export default POWGet;
