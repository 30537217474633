import React from 'react';
import MintRecords from './MintRecords';
import WithdrawRecords from './WithdrawRecords';
import { MintInfo } from '../model/MintInfo';
import { Withdraw } from '../model/Withdraw';
import './RecordTabs.css';

// 定义 props 类型
interface RecordTabsProps {
    activeRecordTab: number;
    myMintRecords?: MintInfo[] | null | undefined; 
    myWithdrawRecords?: Withdraw[] | null | undefined; 
    handleRecordTabClick: (tab: number) => void;
    onClose:any
}

const RecordTabs: React.FC<RecordTabsProps> = ({
    activeRecordTab,
    myMintRecords = [],
    myWithdrawRecords = [],
    handleRecordTabClick,
    onClose
}) => {
    return (
        <div className="overlay-container">
            <div className="record-overlay-new">
                {/* recordList tab */}
                <div className="recordTabs">
                    <button
                        className={activeRecordTab === 1 ? 'active' : ''}
                        onClick={() => handleRecordTabClick(1)}
                    >
                        Mining
                    </button>
                    <button
                        className={activeRecordTab === 2 ? 'active' : ''}
                        onClick={() => handleRecordTabClick(2)}
                    >
                        Withdrawals
                    </button>
                </div>
                <div className="tab-content">
                    {activeRecordTab === 1 && (
                        myMintRecords && myMintRecords.length > 0 ? (
                            <MintRecords mintRecords={myMintRecords} />
                        ) : (
                            <p className="empty-message">No data</p>
                        )
                    )}
                    {activeRecordTab === 2 && (
                        myWithdrawRecords && myWithdrawRecords.length > 0 ? (
                            <WithdrawRecords withdrawRecords={myWithdrawRecords} />
                        ) : (
                            <p className="empty-message">No data</p>
                        )
                    )}
                </div>
                <button className="close-button" onClick={onClose}>Close</button>

            </div>
        </div>);

};

export default RecordTabs;