import React, { useState, useEffect } from 'react';
import './TypewriterText.css'; // 引入样式文件


const TypewriterText = () => {
  const [displayedText, setDisplayedText] = useState('');
  const fullText = 'Only the combination of technology and strong consensus can become the leader of the Bitcoin ecosystem!';

  useEffect(() => {
    let currentIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setDisplayedText(fullText.slice(0, currentIndex + 1));
        currentIndex += 1;
      } else {
        clearInterval(typingInterval);
      }
    }, 100); // 每个字母出现的间隔时间，100毫秒

    return () => clearInterval(typingInterval); // 清除定时器
  }, []);

  return (
    <p className="writer_tx">
      {displayedText}
      <span className="cursor">|</span> {/* 闪烁的光标 */}
    </p>
  );
};


export default TypewriterText;