import React from 'react';
import { Withdraw } from '../model/Withdraw';
import './WithdrawRecords.css';
import ExternalLink from '../utils/ExternalLink';


interface WithdrawRecordsProps {
  withdrawRecords: Withdraw[] | null | undefined;
}

const WithdrawRecords: React.FC<WithdrawRecordsProps> = ({ withdrawRecords }) => {
  if (!withdrawRecords || withdrawRecords.length === 0) {
    return null; // 当 withdrawRecords 为空时，不渲染任何内容
  }
  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th>TokenName</th>
            <th>Type</th>
            <th>Amt</th>
            <th>Status</th>
            <th>Hash</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {withdrawRecords.map((info: any) => (
            <tr key={info.id}>
              {/* <td>{info.minerAddress}</td> */}
              <td>{info.tokenName}</td>
              <td>{info.tokenType}</td>
              <td>{info.quantity}</td>
              <td>  {(() => {
                switch (info.status) {
                  case 2:
                    return 'Transferred';
                  case 3:
                    return 'Confirmed';
                  default:
                    return 'Not transferred';
                }
              })()}</td>
                <td className="hash-cell">
                <ExternalLink hash={"tx/" + info.transHash} />
              </td>
              <td>{new Date(info.createTime).toLocaleString()}</td>
              {/**toLocaleString 可以根据当前用户的区域设置将日期格式化为本地时间格式。**/}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawRecords;