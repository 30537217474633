import React from 'react';
import './Record.css';

const Record = ({ show, onClose, data, columns }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="record-overlay">
      <div className="record">
        <h2 className="h2">My Record</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {row.map((item, idx) => (
                    <td key={idx}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Record;
