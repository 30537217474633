import React from 'react';
import { MintInfo } from '../model/MintInfo';
import { TokenPOS } from '../model/tokenPOS';

interface POSRewardProps {
  minerInfo: any;  // 可根据实际类型定义
  minerStarted: boolean;
  myPOSRewardTokens:TokenPOS[]|null|undefined;  // 确保与实际的myRewardTokens结构一致
  handleWithdrawClick: () => void;
}

const POSReward: React.FC<POSRewardProps> = ({ minerInfo, minerStarted, myPOSRewardTokens, handleWithdrawClick }) => {
  if (!minerInfo || !minerStarted) return null;

  return (
    <div className="panel_info" style={{ alignItems: "center", marginLeft: "0px", position: "relative", width: "200px" }}>
      {Array.from({ length: Math.floor(Math.random() * 41) + 10 }).map((_, index) => {
        const size = Math.random() * 6 + 4;
        const randomLeft = Math.random() * (200 - size) + 15;
        const randomBottom = Math.random() * 100;
        const randomDuration = Math.random() * 3 + 2;
        const randomDelay = Math.random() * 2;

        return (
          <div
            key={index}
            className="bubble_small"
            style={{
              width: `${size}px`,
              height: `${size}px`,
              left: `${randomLeft}px`,
              bottom: `${randomBottom}%`,
              animationDuration: `${randomDuration}s`,
              animationDelay: `${randomDelay}s`,
              zIndex: 1,
              position: "absolute",
            }}
          ></div>
        );
      })}

      <div className="token-images" style={{ height: "100%", marginRight: "20px" }}>
        {myPOSRewardTokens?.map((info, index) => {
          const columns = 4;
          const rows = 2;
          const gridCellWidth = 200 / columns;
          const gridCellHeight = 320 / rows;

          const row = Math.floor(index / columns);
          const col = index % columns;

          const offsetX = Math.random() * (gridCellWidth * 0.3);
          const offsetY = Math.random() * (gridCellHeight * 1);

          return (
            <img
              key={index}
              src={`/parvalue/${info.token.tokenName}.png`}
              alt={info.token.tokenName}
              className="floating-image"
              style={{
                position: 'absolute',
                top: `${row * gridCellHeight + offsetY}px`,
                left: `${col * gridCellWidth + offsetX}px`,
                animationDelay: `${Math.random() * 1}s`,
                filter: 'drop-shadow(0 0 10px rgba(255, 215, 0, 0.9))',
                opacity: 0,
                transform: 'scale(0)',
                transition: 'opacity 8s ease-out, transform 8s ease-out',
              }}
              onLoad={(e) => {
                e.currentTarget.style.opacity = (1).toString();
                e.currentTarget.style.transform = 'scale(1)';
              }}
            />
          );
        })}
      </div>

      <button
        className="minerSwitch normal"
        style={{ position: "absolute", bottom: "15px", zIndex: 10, width: "auto" }}
        onClick={handleWithdrawClick}
        disabled={!myPOSRewardTokens || myPOSRewardTokens.length === 0}>
        Withdraw
      </button>
    </div>
  );
};

export default POSReward;