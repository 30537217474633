import React from 'react';
import './introParvalue.css';

const minerTX = "As Bitcoin mining difficulty increases and rewards decrease, many small mining pools are becoming more and more difficult, while more computing power is becoming more concentrated. We cannot predict whether a 51% attack will come, but we hope that more miners will participate in mining and protect the Bitcoin network as much as possible.  \n \nSo ParValue was born. It will be a subsidy for small mining pools. As long as you are a small mining pool, you will have the opportunity to receive the token."
const claimRule = "1. You are a Bitcoin miner and you package a block such as: 855996(end 6), then you can claim 16MM, and so on... \n \n 2. Look forward to it..."

const blackItems = [
    { icon: '/miner_black/foundryusa.svg', name: 'Foundry USA' },
    { icon: '/miner_black/antpool.svg', name: 'AntPool' },
    { icon: '/miner_black/viabtc.svg', name: 'ViaBTC' },
    { icon: '/miner_black/f2pool.svg', name: 'F2Pool' },
    { icon: '/miner_black/marapool.svg', name: 'MARA Pool' },
    { icon: '/miner_black/spiderpool.svg', name: 'SpiderPool' },
    { icon: '/miner_black/binancepool.svg', name: 'Binance Pool' },
    { icon: '/miner_black/luxor.svg', name: 'Luxor' },
    { icon: '/miner_black/secpool.svg', name: 'SECPOOL' },
    { icon: '/miner_black/braiinspool.svg', name: 'Braiins Pool' },
    { icon: '/miner_black/btccom.svg', name: 'BTC.com' },
    { icon: '/miner_black/whitepool.svg', name: 'WhitePool' },
    { icon: '/miner_black/ultimuspool.svg', name: 'ULTIMUSPOOL' },

];
const introParvalue = () => {
    return (
        <div className='header_container'>
            <text className='header'>WHAT IS PARVALUE?</text>
            <text className='line'></text>
            <div className='miner_container'>
                <img className="miner" src={`${process.env.PUBLIC_URL}/parvalue/miner.png`} style={{ marginRight: '20px'}} alt="Logo" />
                <div className='miner_content'>
                    <text className='miner_title'>What should small Bitcoin miners do?
                    </text>
                    <text className='miner_tx' style={{whiteSpace: "pre-line" }}>{minerTX}
                    </text>
                </div>
            </div>

            <text className='header'>WHO CAN CLAIM PARVALUE</text>
            <text className='line'></text>
            <text className='miner_tx' style={{ whiteSpace: "pre-line" }}>{claimRule} </text>


            <text className='header'>BLACKLIST</text>
            <text className='line'></text>
            <text className='miner_title'>The following mining pools are unable to participate in our subsidy program!
            </text>
            <div className="list-container">
                {blackItems.map((item, index) => (
                    <div key={index} className="list-item">
                        <img src={item.icon} alt={item.name} className="item-icon" />
                        <span className="item-name">{item.name}</span>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default introParvalue;