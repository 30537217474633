import React from 'react';
import './Block.css'; // 引入上面的 CSS 文件
import { BlockData } from '../model/BlockData';
import { formatNumber,timeDifference } from '../utils';


const Block = ({ blockData, onClick, isSelected }: { blockData: BlockData; onClick: () => void; isSelected: boolean }) => {
  return (
    <div className="block-container" onClick={onClick} style={{ cursor: 'pointer' }}>
      <h3 className="block-height">{blockData?.height || '\u00A0'}</h3>
      <div className={`block ${isSelected ? 'selected' : ''}`}>
      <div className="block-content">
          {blockData?.extras ? (
            <>
              <div style={{ fontSize: 14 }}>
                ~{Math.round(blockData.extras.medianFee)} sat/vB
              </div>
              <div style={{ fontSize: 16, color: '#ea9d44', fontWeight: 'bold' }}>
                {formatNumber(blockData.extras.totalFees)} sats
              </div>
              <div>{blockData.tx_count} tx</div>
              <div style={{ color: '#666' }}>
                {timeDifference(blockData.timestamp)}
              </div>
            </>
          ) : (
            <p></p> // 当 blockData.extras 为空时显示的内容
          )}
        </div>
      </div>
      <div className="miner-info">
        {/* <img src={blockData.extras.pool.} alt="Miner" /> */}
        <span>{blockData?.extras?.pool.name}</span>
      </div>
      {isSelected && <div className="arrow-indicator"></div>}
  
    </div>
  );
};
  
  export default Block;