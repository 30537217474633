import React, { useState, useEffect, useRef, useContext } from 'react';
import './POSMint.css';
import axios from 'axios';
import Block from './Block';
import { BlockData } from '../model/BlockData';
import WalletMenu from '../wallet/wallet_menu';
import { WalletContext } from '../wallet/WalletContext';
import Okx from '../okx_web3/okx';
import Unisat from '../unisat/unisat';
import Menu from '../menu/Menu';
import Swal from 'sweetalert2';

import { production, production_server } from '../config';
import { configureNetwork, network, okxNetwork, baseURL, mempoolUrl } from '../config_info';
import { Pledge } from '../model/Pledge';
import { Miner } from '../model/Miner';
import { MintInfo } from '../model/MintInfo';
import { Withdraw } from '../model/Withdraw';

import { shortenString, faceValues } from '../utils';
import RecordTabs from './RecordTabs';
import POSReward from './POSReward';
import NormalReward from './NormalReward';
import { tokenNames } from '../cons/constant';

import ExternalLink from '../utils/ExternalLink';


import { TokenPOS } from '../model/tokenPOS';


configureNetwork(production, production_server);

declare global {
  interface Window {
    unisat?: any;
    okxwallet?: any;  // 添加 okxwallet 到 Window 接口
    bitcoinTestnet?: any;   // 测试网络
  }
}
interface OkxRefType {
  connectOkxWallet: (okxNetwork: any) => void;
}
interface UnisatRefType {
  connectUnisatWallet: (network: string) => void;
}


const MAX_PAGE_LOADS = 15; // 定义fetchBlocks最多加载的页数

// 示例数据
const whiteListData = ['Item 1', 'Item 2', 'Item 3'];
const miningData = ['Data A', 'Data B', 'Data C'];



const POSMint = () => {
  const showSpinner = () => {
    document.getElementById('spinner-overlay')!.style.visibility = 'visible';
  };

  const hideSpinner = () => {
    document.getElementById('spinner-overlay')!.style.visibility = 'hidden';
  };

  const { btnConnectText, setBtnConnectText, walletConnected, setWalletConnected, walletAddress, setWalletAddress } = useContext(WalletContext);
  const [selectedWallet, setSelectedWallet] = useState<number>(-1);//0:unisat 1:okx web3
  const [showWalletMenu, setWalletMenu] = useState(false);
  const walletMenuItems = [
    { icon: '/parvalue/unisat.svg', text: 'Unisat' },
    { icon: '/parvalue/okx_wallet.svg', text: 'Okx web3' }
  ];
  const okxRef = useRef<OkxRefType>(null);
  const unisatRef = useRef<UnisatRefType>(null);
  const textRef = useRef<HTMLDivElement>(null);

  //menu
  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const items = ["My Record", "Disconnect"];
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });


  const [selectedBlockIndex, setSelectedBlockIndex] = useState(0); // 默认选中第一个区块

  /**wallet**/
  const connectWalletClick = () => {
    if (walletConnected) {
      if (textRef.current) {
        const rect = textRef.current.getBoundingClientRect();
        setMenuPosition({ top: rect.bottom + 10, left: rect.left - 80 });
        setShowMenu(!showMenu);
      }
      return
    }
    setWalletMenu(true);

  };
  const walletMenuItemClick = (item: { icon: string; text: string }, index: number) => {
    console.log(`Clicked on ${item.text} at index ${index}`);
    setWalletMenu(false);
    setSelectedWallet(index)
    if (index === 0) {
      if (unisatRef.current) {
        unisatRef.current.connectUnisatWallet(network);
      }
    } else if (index === 1) {
      if (okxRef.current) {
        okxRef.current.connectOkxWallet(okxNetwork);
      }
    }
  };
  const walletMenuClose = () => {
    setWalletMenu(false);
  };
  /**wallet  end**/


  /*******************menu*************************/
  const menuItemClick = async (item: string, index: number) => {
    console.log(`Clicked on ${item}`);
    setShowMenu(false); // Hide menu after clicking an item
    if (index === 0) {
      setShowRecordTabs(true)
      await getMyMintRecords()
      await getMyWithdrawRecords()
    } else if (index === 1) {
      handleLogout()
    }
  };

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && textRef.current && !textRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);
  /*******************menu end*************************/

  /**mempool Block*****************************************/
  const [blockDataList, setBlockDataList] = useState<BlockData[] | null | undefined>(undefined); // 初始为 undefined
  const [loading, setLoading] = useState(false); // 数据加载状态
  const [pageCount, setPageCount] = useState(0); // 已加载的页数
  const [hasMore, setHasMore] = useState(true); // 用于判断是否还有更多数据加载
  const listEndRef = useRef<HTMLDivElement | null>(null); // 列表末尾的元素引用
  const blockListRef = useRef<HTMLDivElement | null>(null); // 列表容器引用

  //从mempool请求block数据(每次返回15个block)
  async function fetchBlocks(blockNum: any) {
    if (loading || !hasMore || pageCount >= MAX_PAGE_LOADS) return; // 如果正在加载或没有更多数据或已达到页数限制，则不执行请求
    setLoading(true);
    try {
      const url = blockNum ? `${mempoolUrl}/v1/blocks/${blockNum}` : `${mempoolUrl}/v1/blocks`;
      const response = await axios.get(url);

      const newData = response.data
      if (newData.length === 0) {
        setHasMore(false); // 如果没有新数据，说明没有更多数据了
      } else {
        setBlockDataList((prevData) => [...(prevData ?? []), ...newData]);
        setPageCount((prevCount) => prevCount + 1); // 页数递增
        if (pageCount == 0) {
          getPOSMintInfo(newData[0].height)
        }

      }
    } catch (error) {
      console.error('Failed to fetch Blocks:', error);
    } finally {
      setLoading(false);
    }
  }

  // 当页面滚动到最右边时加载更多数据
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && blockDataList) {
          const lastBlockHeight = blockDataList.length > 0 ? blockDataList[blockDataList.length - 1].height - 1 : undefined;
          fetchBlocks(lastBlockHeight); // 获取下一页数据，传递最后一个区块的高度
        }
      },
      {
        root: blockListRef.current, // 设置根元素为 blockList 容器
        rootMargin: '0px', // 设置 rootMargin 为 0
        threshold: 1.0, // 当最后一个元素 100% 可见时触发
      }
    );

    if (listEndRef.current) {
      observer.observe(listEndRef.current); // 观察列表末尾的元素
    }

    return () => {
      if (listEndRef.current) {
        observer.unobserve(listEndRef.current); // 组件卸载时停止观察
      }
    };
  }, [blockDataList, loading]); // 监听 blockDataList 和 loading 状态


  /**
   * 点击区块时获取pos挖矿信息
   * **/
  const [POSMintInfo, setPOSMintInfo] = useState<MintInfo[]>();

  const getPOSMintInfo = async (blockNum: any) => {
    console.log("blockNum:" + blockNum)
    try {
      const result = await axios.get(baseURL + '/api/infos/getPOSMintInfo', {
        params: {
          blockNum: blockNum,
        }
      });
      setPOSMintInfo(result.data);
      console.log('fetch getPOSMintInfo:', result.data);
    } catch (error) {
      console.error('Failed to fetch getPOSMintInfo:', error);
    }
  };

  const handleBlockClick = (index: number) => {
    setSelectedBlockIndex(index);
    getPOSMintInfo(getBlockData(blockDataList, index).height)
  };

  function getBlockExtras(blockDataList: any, selectedBlockIndex: any, field: any) {
    return blockDataList?.[selectedBlockIndex]?.extras?.[field] || "No data";
  }

  function getBlockData(blockDataList: any, selectedBlockIndex: any) {
    return blockDataList?.[selectedBlockIndex] || "No data";
  }

  /**tab**/
  const [activeTab, setActiveTab] = useState<number>(2);
  // 点击 Tab 时，更新当前的 activeTab
  const handleTabClick = async (tabIndex: number) => {
    setActiveTab(tabIndex);
    if (tabIndex == 2) {
      await getMinerStarted()
    } else if (tabIndex == 3) {
      if (walletConnected) {
        if (isWhite) {
          await getMyMiner()
        } else {
          await getMyNormalRewardInfo()
        }
      }
      //test phone
      // await getMyMiner()

    }
  };

  /**质押****************************************************/
  const [isPledge, setIsPledge] = useState<boolean>();
  const [isVisiblePledgeEnter, setIsVisiblePledgeEnter] = useState(true);//质押按钮显示状态

  /**checkPledge 检查是否质押以及是否白名单
   * started 0:未点击质押入口 1:已点击质押入口
   * **/
  const checkPledge = async (started: any) => {
    showSpinner()
    try {
      // console.log("checkPledge walletAddress:" + walletAddress)
      const params = {
        walletAddress: walletAddress,
        started: started
      };
      const result = await axios.post(baseURL + '/api/infos/checkPledge', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setIsWhite(result.data.isWhite);
      setIsPledge(result.data.isPledge);
      console.log('fetch checkPledge:', result.data);
      setIsVisiblePledgeEnter(true)
      if (result.data.isPledge) {//已经质押过的
        if (!result.data.isWhite) {//非白名单可以追加质押
          setIsVisiblePledgeEnter(true)
          if (started) {
            showAlertPledge(result.data.tick, result.data.isWhite, true, result.data.pledgeAmt_limit, result.data.inscriptionId, result.data.toAddress)
          }
        } else {
          setIsVisiblePledgeEnter(false)
        }
      } else if (started) {
        showAlertPledge(result.data.tick, result.data.isWhite, false, result.data.pledgeAmt_limit, result.data.inscriptionId, result.data.toAddress)
      }
    } catch (error) {
      console.error('Failed to fetch checkPledge:', error);
    } finally {
      hideSpinner()
    }
  };

  const clickClaimPOW = async () => {
    // 设置跳转的 URL
    const url = '/claim';
    // 跳转到第三方页面
    window.open(url, '_blank');
  };

  const clickHome = async () => {
    // 设置跳转的 URL
    const url = '/home';
    // 跳转到第三方页面
    window.open(url, '_blank');
  };


  const clickPledge = async () => {
    if (!walletConnected) {
      connectWalletClick(); // 钱包未连接，调用连接函数
      return
    }
    await checkPledge(true)
  };


  /**submitPledge**/
  const submitPledge = async (pledgeHash: any, cycle: any) => {
    showSpinner()
    try {
      const params = {
        pledgeHash: pledgeHash,
        cycle: cycle,
        walletAddress: walletAddress
      };
      console.log("pledgeHash:" + pledgeHash + "-cycle:" + cycle)
      const result = await axios.post(baseURL + '/api/pledge/submitPledge', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200) {
        setIsPledge(true);
        showAlertSuccess("Staking successful!")
        await getMyNormalRewardInfo()
      }
      console.log('fetch submitPledge:', result.data);
    } catch (error) {
      console.error('Failed to fetch submitPledge:', error);
    } finally {
      hideSpinner()
    }
  };


  /**发送铭文**/
  const sendInscription = async (toAddress: any, inscriptionId: any, cycle: any) => {
    try {
      let txid
      if (selectedWallet == 0) {
        txid = await window.unisat.sendInscription(toAddress, inscriptionId);
      } else if (selectedWallet == 1) {
        txid = await okxNetwork.sendInscription(toAddress, inscriptionId);
      }
      console.log("send Inscription:", txid)
      await submitPledge(txid, cycle)
    } catch (e) {
      console.log(e);
    }
  };


  function showAlertPledge(tick: any, isWhite: any, isPledge: any, pledgeAmt_limit: any, inscriptionId: any, toAddress: any) {
    let text = ""
    let conten
    const tip_yesBalance = isPledge ? "You have already staked. If you stake again, the reward cycle will be recalculated!" : "You can stake!"
    const tip_noBalance = "Insufficient balance, please inscribe transfer in your wallet first!"
    if (isWhite) {//<p style="font-size: 16px; color: gray;">transferable: ${amt_trans}</p>
      text = `You are whitelisted and  need to stake ${pledgeAmt_limit} * ${tick}.`
      conten = `
      <p style="font-size: 18px; color: black;">${text}</p> 
      `
      if (inscriptionId != null) {
        conten += `<p style="font-size: 15px; color: green;">${tip_yesBalance}</p>`
      } else {
        conten += `<p style="font-size: 15px; color: red;">${tip_noBalance}</p>`
      }
    } else {
      text = `You are not whitelisted and can earn interest by staking.The minimum amt is ${pledgeAmt_limit} * ${tick}.`
      conten = `
      <p style="font-size: 18px; color: black;">${text}</p> 
       <div style="display: flex; justify-content: center; gap: 15px; width: auto;">
        <label style="display: inline-block;">
          <input type="radio" id="radio1" name="pledgeRadio" value="0" checked>
          1 month
        </label>

        <label style="display: inline-block;">
          <input type="radio" id="radio2" name="pledgeRadio" value="1">
           6 month
        </label>

        <label style="display: inline-block;">
          <input type="radio" id="radio3" name="pledgeRadio" value="2">
         12 month
        </label>
      </div>
      `
      if (inscriptionId != null) {
        conten += `<p style="font-size: 15px; color: green;">${tip_yesBalance}</p>`
      } else {
        conten += `<p style="font-size: 15px; color: red;">${tip_noBalance}</p>`
      }
    }

    Swal.fire({
      title: "Staking",
      text: text,
      html: conten,
      showCancelButton: false,
      scrollbarPadding: false, // 禁用滚动条
      confirmButtonText: 'Stake',
      didOpen: () => {
        // 获取按钮 DOM 元素
        const confirmButton = Swal.getConfirmButton();
        if (confirmButton) {
          // 根据 inscriptionId 值来判断按钮是否可点击
          if (inscriptionId == null) {
            confirmButton.disabled = true; // 当 inscriptionId 无效时，禁用按钮
          } else {
            confirmButton.disabled = false; // 当 inscriptionId 有效时，启用按钮
          }
        }
      },
      preConfirm: async () => {
        if (inscriptionId != null) {
          let selectedValue = -1
          if (!isWhite) {
            // 获取选中的单选按钮
            const selectedRadio = document.querySelector('input[name="pledgeRadio"]:checked') as HTMLInputElement;
            selectedValue = Number(selectedRadio.value)// 将值转换为 number 类型
            console.log('Selected Radio:', selectedValue);
          }
          // await submitPledge("0xxxxxxxxxx", selectedValue)
          await sendInscription(toAddress, inscriptionId, selectedValue)
          return true; // 如果 inscriptionId 有效，允许确认
        }
      }

    });
  }


  function showAlertSuccess(text: any) {
    Swal.fire({
      title: "Tip",
      text: text,
      icon: "success",
      showCancelButton: false,
      scrollbarPadding: false, // 禁用滚动条
      allowOutsideClick: false,  // 禁止点击空白处取消
      confirmButtonText: 'ok',
    }).then((result) => {

    });
  }
  function showAlertError(text: any) {
    Swal.fire({
      title: "Tip",
      text: text,
      icon: "error",
      showCancelButton: false,
      scrollbarPadding: false, // 禁用滚动条
      allowOutsideClick: false,  // 禁止点击空白处取消
      confirmButtonText: 'ok',
    }).then((result) => {

    });
  }

  /**铭刻铭文 ok钱包api不支持移动端铭刻，所以需要用户自己先铭刻**/
  // const inscri = async () => {
  //   try {
  //     let txid = await window.unisat.inscribeTransfer("16MM", "12");
  //     console.log("inscri Inscription:", txid)
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  /**initData **/
  const handleLogout = () => {
    setWalletConnected(false);
    setWalletAddress(null);
    setBtnConnectText('Connect');
    setIsVisiblePledgeEnter(true)

    initData()

  };
  // 监听 walletConnected 状态变化，等到连接成功后再执行 initData
  useEffect(() => {
    if (walletConnected && walletAddress) {
      checkPledge(false)
    }
  }, [walletConnected, walletAddress]); // 当 walletConnected 变为 true 时，调用 initData

  //用户切换钱包后清除数据
  const initData = async () => {
    setMinerInfo(null);
    setMyPOSRewardIDs(null)
    setMyPOSRewardTokens(null)
    setMyNormalPledge(null)
    setInterest_rates(null)
    setMyMintRecords(null)
    setMyWithdrawRecords(null)
    setTransactionCreated(false);

  };
  /**initData end**/

  /**
    * getWhiteList
    * 
    * **/
  const [whiteList, setWhiteList] = useState<Pledge[]>();
  const [isWhite, setIsWhite] = useState<boolean>();


  const getWhiteList = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getWhiteList');
      setWhiteList(result.data);
      console.log('fetch getWhiteList:', result.data);
    } catch (error) {
      console.error('Failed to fetch getWhiteList:', error);
    }
  };


  /**
   * getMinerStarted获取所有在运行的矿机
   * **/
  const [minerStartedList, setMinerStartedList] = useState<Miner[]>();

  const getMinerStarted = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getMinerStarted');
      setMinerStartedList(result.data);
      console.log('fetch getMinerStarted:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMinerStarted:', error);
    }
  };


  // useEffect(() => {
  //   // 在组件挂载时请求数据
  //   getBtcPrice()
  //   getWhiteList();
  //   getMinerStarted();
  // }, []); // 空数组作为依赖项，确保只在组件挂载时调用一次

  useEffect(() => {
    // 初始加载时立即调用
    getBtcPrice();
    getWhiteList();
    getMinerStarted();

    // 设置一个每分钟调用一次的定时器
    const intervalId = setInterval(() => {
      getBtcPrice();
    }, 60000); // 60000 毫秒 = 1 分钟

    // 清除定时器，防止内存泄漏
    return () => clearInterval(intervalId);
  }, []); // 空数组作为依赖项，确保只在组件挂载时调用一次


  useEffect(() => {
    // 立即调用 fetchBlocks 一次
    const resetAndFetchBlocks = async () => {
      setBlockDataList(null);// 清空当前的 blocks 数据
      setPageCount(0); // 重置页码到第一页
      setLoading(false);
      setHasMore(true)
      await fetchBlocks(""); // 重新获取第一页的数据
    };

    // 立即调用一次 fetchBlocks
    resetAndFetchBlocks();

    // 设置一个定时器，每 10 分钟调用一次 fetchBlocks (10 分钟 = 600,000 毫秒)
    const intervalId = setInterval(() => {
      resetAndFetchBlocks(); // 每 10 分钟重置并重新请求数据
    }, 10 * 60 * 1000);

    // 在组件卸载时清除定时器
    return () => clearInterval(intervalId);
  }, []); // 空依赖数组，确保只在组件挂载和卸载时运行


  /**my panel POS==========================================================**/
  const [minerStarted, setMinerStarted] = useState(false);
  const [minerInfo, setMinerInfo] = useState<Miner | null>();
  const [minBalance, setMinBalance] = useState<number | 0>(0);

  //启动暂停矿机事件
  const handleMinerSwitchBtnClick = async (minerAddress: any) => {
    await minerSwitch(minerStarted ? 0 : 1, minerAddress)
  };

  /**minerSwitch**/
  const minerSwitch = async (status: any, minerAddress: any) => {
    showSpinner()
    try {
      const params = {
        status: status,
        minerAddress: minerAddress,
        walletAddress: walletAddress
      };
      const result = await axios.post(baseURL + '/api/miner/minerSwitch', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200) {
        if (status === 0) {
          showAlertSuccess("Pause successful!")
        } else if (status === 1) {
          showAlertSuccess("Start successful!")
        }
        await getMyMiner()//刷新矿机页面
      }
      console.log('fetch minerSwitch:', result.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {  // 类型守卫，检查是否为 AxiosError
        if (error.response && error.response.status === 400) {
          showAlertError(error.response.data);  // 显示服务器返回的错误信息
        } else {
          showAlertError('Unexpected error occurred.');
        }
      } else {
        console.error('Failed to fetch minerSwitch:', error);
      }
    } finally {
      hideSpinner()
    }
  };

  //获取自己的矿机信息
  const getMyMiner = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getMiner', {
        params: {
          walletAddress: walletAddress,
          // walletAddress: "tb1pghp606n2hfl59m982glqjuzm3amnmflp0w2gxs392j0fsvl3mxnsx8wg5n",

        }
      });
      if (result.status === 200) {
        setMinerInfo(result.data.infos);
        setMinBalance(result.data.minBalance)
        setMinerStarted(result.data.status === 0 ? false : true);
        await getMyPOSRewardInfo()

      }
      console.log('fetch getMiner:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMiner:', error);
    }
  };


  /**获取POS挖矿收益**/
  const [myPOSRewardTokens, setMyPOSRewardTokens] = useState<TokenPOS[] | null>();
  const [myPOSRewardIDs, setMyPOSRewardIDs] = useState<number[] | null>();

  const getMyPOSRewardInfo = async () => {
    try {
      const params = {
        walletAddress: walletAddress
        // walletAddress: "tb1pghp606n2hfl59m982glqjuzm3amnmflp0w2gxs392j0fsvl3mxnsx8wg5n",

      };
      const result = await axios.post(baseURL + '/api/withdraw/getPOSRewardInfo', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200 && result.data !== null) {
        setMyPOSRewardIDs(result.data[0])
        setMyPOSRewardTokens(result.data[1])
      }
      console.log('fetch getMyPOSRewardInfo:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMyPOSRewardInfo:', error);
    } finally {
    }
  };

  /**my panel NORMAL==========================================================**/
  /**获取普通质押生息收益**/
  const [myNormalPledge, setMyNormalPledge] = useState<Pledge | null>();
  const [interest_rates, setInterest_rates] = useState<number[] | null>();

  const getMyNormalRewardInfo = async () => {
    try {
      const params = {
        walletAddress: walletAddress
        // walletAddress: "tb1pghp606n2hfl59m982glqjuzm3amnmflp0w2gxs392j0fsvl3mxnsx8wg5n",

      };
      const result = await axios.post(baseURL + '/api/withdraw/getNormalRewardInfo', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200 && result.data !== null) {
        setMyNormalPledge(result.data.pledge)
        setInterest_rates(result.data.interest_rate)
      }
      console.log('fetch getMyNormalRewardInfo:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMyNormalRewardInfo:', error);
    } finally {
    }
  };


  /**挖矿记录和提现tab**/
  const [showRecordTabs, setShowRecordTabs] = useState(false); // 控制 RecordTabs 显示状态
  const [activeRecordTab, setActiveRecordTab] = useState<number>(1);
  // 点击 Tab 时，更新当前的 activeTab
  const handleRecordTabClick = async (tabIndex: number) => {
    setActiveRecordTab(tabIndex);
    // if (tabIndex == 1) {
    //   await getMyMintRecords()
    // } else if (tabIndex == 2) {
    //   await getMyWithdrawRecords()
    // }
  };
  const handleCloseRecord = () => {
    setShowRecordTabs(false)
    setMyMintRecords(null);
    setMyWithdrawRecords(null);
  };
  //获取自己的挖矿记录
  const [myMintRecords, setMyMintRecords] = useState<MintInfo[] | null>();
  const getMyMintRecords = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getMintInfoByAddress', {
        params: {
          walletAddress: walletAddress,
          // walletAddress: "tb1pghp606n2hfl59m982glqjuzm3amnmflp0w2gxs392j0fsvl3mxnsx8wg5n",

        }
      });
      if (result.status === 200) {
        setMyMintRecords(result.data);
      }
      console.log('fetch getMyMintRecords:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMyMintRecords:', error);
    }
  };



  //获取自己的提现记录
  const [myWithdrawRecords, setMyWithdrawRecords] = useState<Withdraw[] | null>();
  const getMyWithdrawRecords = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getWithdrawInfoByAddress', {
        params: {
          walletAddress: walletAddress,
          // walletAddress: "tb1pghp606n2hfl59m982glqjuzm3amnmflp0w2gxs392j0fsvl3mxnsx8wg5n",

        }
      });
      if (result.status === 200) {
        setMyWithdrawRecords(result.data);
      }
      console.log('fetch getMyWithdrawRecords:', result.data);
    } catch (error) {
      console.error('Failed to fetch getMyWithdrawRecords:', error);
    }
  };

  /**************普通提现***************/
  const handleWithdrawNormalClick = () => {
    showRadioNormalWithdraw()
  };

  const showRadioNormalWithdraw = () => {
    if (!interest_rates || !myNormalPledge) {
      return
    }
    // 根据id获取对应的tokenName
    const tokenName = tokenNames[myNormalPledge.pledgeTokenID as keyof typeof tokenNames] || "default";
    const interest_rate = interest_rates[myNormalPledge.cycle]
    const pledgeAmt = myNormalPledge.pledgeAmt
    const allAuantity = pledgeAmt + Math.round(interest_rate * pledgeAmt / 100)//四舍五入为整数
    console.log("interest_rate:" + interest_rate + "--allAuantity:" + allAuantity)

    const textHTML = `<span style="font-size: 18px;color: #ea9d44;">${tokenName} * ${allAuantity}</span>`
    const tipHTML = `<span style="font-size: 14px;color: #a09d99;">(${pledgeAmt}+${pledgeAmt} * ${interest_rate}%)</span>`

    Swal.fire({
      title: 'You can withdraw',
      html: `
        <div>
          ${textHTML}
            <br/>
          ${tipHTML}
        </div>
      `,

    }).then(async result => {
      if (result.isConfirmed) {
        console.log("selectTokens")
        await getNonce(tokenName, 0)
      }
    });
  }

  /**************POS提现***************/
  const handleWithdrawClick = () => {
    showRadioPOSWithdraw()
  };

  const showRadioPOSWithdraw = () => {
    // const myRewardInfo = [
    //   { token: { id: 1, tokenName: "10MM", faceValue: 10, quantity: 10 }, count: 5 },
    //   { token: { id: 2, tokenName: "11MM", faceValue: 11, quantity: 10 }, count: 4 },
    //   { token: { id: 3, tokenName: "15MM", faceValue: 15, quantity: 10 }, count: 1 },
    //   { token: { id: 4, tokenName: "16MM", faceValue: 16, quantity: 10 }, count: 1 },
    //   { token: { id: 7, tokenName: "20MM", faceValue: 20, quantity: 10 }, count: 2 },
    //   { token: { id: 8, tokenName: "21MM", faceValue: 21, quantity: 10 }, count: 3 }
    // ];

    if (!myPOSRewardIDs || !myPOSRewardTokens) {
      return
    }

    // 查找是否同时存在10MM和20MM
    const token10MM = myPOSRewardTokens.find(item => item.token.tokenName === "10MM");
    const token20MM = myPOSRewardTokens.find(item => item.token.tokenName === "20MM");

    // 查找是否同时存在11MM和21MM
    const token11MM = myPOSRewardTokens.find(item => item.token.tokenName === "11MM");
    const token21MM = myPOSRewardTokens.find(item => item.token.tokenName === "21MM");



    // 生成固定显示的 tokens 文本 (如果没有形成组合，则保留10MM, 20MM, 11MM, 21MM)
    const fixedTokensHtml = myPOSRewardTokens
      .filter(item => {
        // 保留未成对的 token
        if (item.token.tokenName === "10MM" && !token20MM) return true;
        if (item.token.tokenName === "20MM" && !token10MM) return true;
        if (item.token.tokenName === "11MM" && !token21MM) return true;
        if (item.token.tokenName === "21MM" && !token11MM) return true;

        // 过滤掉成对的 token
        return !["10MM", "20MM", "11MM", "21MM"].includes(item.token.tokenName);
      })
      .map(item => `<span style="font-size: 18px;color: #ea9d44;">${item.token.tokenName} * ${item.token.quantity * item.count}</span>、`)
      .join('');


    // 生成10MM和20MM的选择框
    let group1Html = '';
    if (token10MM && token20MM) {
      group1Html = `
    <p style="font-size: 14px; color: #a09d99;">10MM or 20MM:</p>
    <label style="font-size: 18px;color: #ea9d44;"><input type="radio" name="group1" value="10MM"> 10MM * ${token10MM.token.quantity * token10MM.count}</label><br/>
     <label style="font-size: 18px;color: #ea9d44;"><input type="radio" name="group1" value="20MM"> 20MM * ${token20MM.token.quantity * token20MM.count}</label><br/>
  `;
    }

    // 生成11MM和21MM的选择框
    let group2Html = '';
    if (token11MM && token21MM) {

      group2Html = `
     <p style="font-size: 14px; color: #a09d99;">11MM or 21MM:</p>
    <label style="font-size: 18px;color: #ea9d44;"><input type="radio" name="group2" value="11MM"> 11MM * ${token11MM.token.quantity * token11MM.count}</label><br/>
     <label style="font-size: 18px;color: #ea9d44;"><input type="radio" name="group2" value="21MM"> 21MM * ${token21MM.token.quantity * token21MM.count}</label><br/>
  `;
    }

    const group3Html = `
     <p style="font-size: 17px; color: #000;">Select inscription type:</p>
    <label><input type="radio" name="group3" value="0" checked>BRC20-4byte</label>
    <label><input type="radio" name="group3" value="1">BRC20-5byte</label>
    <label><input type="radio" name="group3" value="2">SRC20</label>
  `;

    Swal.fire({
      title: 'You can withdraw',
      html: `
        <div>
          ${fixedTokensHtml} <!-- 固定显示的 tokens -->
          ${group1Html} <!-- 10MM 和 20MM 的选择框（如果存在）-->
          ${group2Html} <!-- 11MM 和 21MM 的选择框（如果存在）-->
          <br/>
          ${group3Html} <!-- 选择领取的铭文类型:brc20-4、brc20-5、src20)-->
        </div>
      `,
      preConfirm: () => {
        let group1Selection: HTMLInputElement | null = null;
        let group2Selection: HTMLInputElement | null = null;
        let group3Selection: HTMLInputElement | null = null;

        // 如果有10MM和20MM的选择，则获取用户选择的值
        if (token10MM && token20MM) {
          group1Selection = document.querySelector('input[name="group1"]:checked') as HTMLInputElement;
          if (!group1Selection) {
            return Swal.showValidationMessage('You need to select one option from 10MM and 20MM!');
          }
        }

        // 如果有11MM和21MM的选择，则获取用户选择的值
        if (token11MM && token21MM) {
          group2Selection = document.querySelector('input[name="group2"]:checked') as HTMLInputElement;
          if (!group2Selection) {
            return Swal.showValidationMessage('You need to select one option from 11MM and 21MM!');
          }
        }
        // 获取 group3 的值 (inscription type)
        group3Selection = document.querySelector('input[name="group3"]:checked') as HTMLInputElement;
        if (!group3Selection) {
          return Swal.showValidationMessage('You need to select an inscription type!');
        }

        return {
          group1: group1Selection ? group1Selection.value : null,
          group2: group2Selection ? group2Selection.value : null,
          group3: group3Selection.value // 返回 inscription type 的选择
        };
      }
    }).then(async result => {
      if (result.isConfirmed) {
        const selectedGroup1 = result.value.group1;
        const selectedGroup2 = result.value.group2;
        const selectedGroup3 = result.value.group3;

        console.log("Group 1:", selectedGroup1);
        console.log("Group 2:", selectedGroup2);
        console.log("Group 3:", selectedGroup3);
        //保存选择的token
        let selectTokens = []
        if (selectedGroup1 != null) {
          selectTokens.push(selectedGroup1)
        }
        if (selectedGroup2 != null) {
          selectTokens.push(selectedGroup2)
        }
        console.log("selectTokens:" + selectTokens + "--tokenType:" + selectedGroup3)

        await getNonce(selectTokens, selectedGroup3)
      }
    });

  };



  /***提现时获取nonce***/
  const getNonce = async (selectTokens: any, tokenType: any) => {
    try {
      const params = {
        walletAddress: walletAddress,
      };
      const result = await axios.post(baseURL + '/api/infos/getNonce', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('fetch getNonce:', result.data);
      if (result.status === 200) {
        const nonce = result.data.nonce
        await signMessage(nonce, selectTokens, tokenType)
      }

    } catch (error) {
      console.error('Failed to fetch getNonce:', error);
    } finally {
    }
  };

  /**钱包签名nonce**/
  async function signMessage(nonce: any, selectTokens: any, tokenType: any) {
    try {
      // const options = { feeRate: feeRateSend, nSequence: 0xffffffff };  // (optional)the network fee rate
      let sign, publicKey
      if (selectedWallet == 0) {
        sign = await window.unisat.signMessage(nonce);
        publicKey = await window.unisat.getPublicKey();

      } else if (selectedWallet == 1) {
        sign = await okxNetwork.signMessage(nonce)
        publicKey = await okxNetwork.getPublicKey();
      }
      console.log("signMessage:" + sign)
      if (sign != null) {
        await getEstimateFee(publicKey, sign, nonce, selectTokens, tokenType)
      }
    } catch (error) {
      console.error('Failed to  signMessage:', error);
      // setResponse('Transaction Failed');
    }
    finally {
    }
  };
  /***提现时获取fee***/
  const getEstimateFee = async (publicKey: any, sign: any, nonce: any, selectTokens: any, tokenType: any) => {
    try {
      const params = {
        walletAddress: walletAddress,
        selectTokens: selectTokens,
        tokenType: tokenType,
        isWhite: isWhite
      };
      const result = await axios.post(baseURL + '/api/withdraw/getEstimateFee', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('fetch getEstimateFee:', result.data);
      if (result.status === 200) {
        const estimateFee = result.data.estimateFee
        const feeRate = result.data.feeRate
        const payAddress = result.data.getPayAddress
        await createTransaction(publicKey, sign, nonce, estimateFee, feeRate, payAddress, selectTokens)
        // await commitTrans(publicKey,sign, nonce, payAddress, 10086, feeRate, "d70ada8b7d195a53858d1fa421e4093368074f8414e2de1d88e90d07bf7bf24a", selectTokens)
      }

    } catch (error) {
      console.error('Failed to fetch getEstimateFee:', error);
    } finally {
    }
  };


  /***弹起钱包发送手续费***/
  const [transactionCreated, setTransactionCreated] = useState(false);

  async function createTransaction(publicKey: any, sign: any, nonce: any, satoshis: any, feeRate: any, payAddress: any, selectTokens: any) {
    try {
      // const options = { feeRate: feeRateSend, nSequence: 0xffffffff };  // (optional)the network fee rate
      let txid
      if (selectedWallet == 0) {
        txid = await window.unisat.sendBitcoin(payAddress, satoshis);
      } else if (selectedWallet == 1) {
        txid = await okxNetwork.sendBitcoin(payAddress, satoshis);
      }
      console.log('Transaction created:', txid + "--selectedWallet:" + selectedWallet);
      if (txid != null) {
        showSpinner()
        setTransactionCreated(true);
        await commitTrans(publicKey, sign, nonce, payAddress, satoshis, feeRate, txid, selectTokens)
      }
    } catch (error) {
      console.error('Failed to create transaction:', error);
      // setResponse('Transaction Failed');
    }
    finally {
      hideSpinner()
    }
  };

  /**提交支付信息**/
  async function commitTrans(publicKey: any, sign: any, nonce: any, payAddress: any, payGasFee: any, feeRate: any, payHash: any, selectTokens: any) {
    try {
      const params = {
        walletAddress: walletAddress,
        publicKey: publicKey,
        signature: sign,
        nonce: nonce,
        payAddress: payAddress,
        payGasFee: payGasFee,
        feeRate: feeRate,
        payHash: payHash,
        selectTokens: selectTokens,
        rewardInfos: [myPOSRewardIDs, myPOSRewardTokens]
      };
      console.log("commitTrans params:" + JSON.stringify(params))
      let funName = ""
      if (isWhite) {
        funName = "withdrawPOS"
      } else {
        funName = "withdrawNormal"
      }
      const result = await axios.post(baseURL + '/api/withdraw/' + funName, params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200) {
        if (isWhite) {
          setMyPOSRewardIDs(null)
          setMyPOSRewardTokens(null)
          await getMyMiner()
        } else {
          setMyNormalPledge(null)
          setInterest_rates(null)
        }
        console.log('fetch commitTrans:', result.data);
        showAlertSuccess("Your withdrawal has been submitted!")
      }
    } catch (error) {
      console.error('Failed to fetch commitTrans:', error);
      // setResponse('Failed to fetch commitTrans');
    }
  };




  //充值事件
  const handleRechargeClick = (minerAddress: any) => {
    showAlertRecharge(minerAddress)
  };
  async function showAlertRecharge(minerAddress: any) {
    const btcPrice = btcPriceData[0]
    const minBtc = 3 * btcPriceData[2] * 118 / 100000000 //最小充值金额
    console.log("minBtc:" + minBtc)
    Swal.fire({
      title: 'Recharge',
      input: 'number', // 输入类型为数字
      inputPlaceholder: "Minimum amount:" + minBtc,
      inputAttributes: {
        min: minBtc + "", // 允许输入小数，步长为0.01
        step: 'any'
      },
      confirmButtonText: 'Confirm',
      html: `
      <div>
        <p>about: $<span id="usdtAmount">0.00</span></p> <!-- 用于显示实时 USDT 金额 -->
      </div>
    `,
      didOpen: () => {
        const input = Swal.getInput(); // 获取输入框元素
        const usdtAmountElement = document.getElementById('usdtAmount'); // 获取用于显示 USDT 的元素
        if (usdtAmountElement != null) {
          // 监听输入框的输入事件
          input?.addEventListener('input', function () {
            const btcAmount = parseFloat(input.value);
            if (!isNaN(btcAmount)) {
              const usdtAmount = (btcAmount * btcPrice).toFixed(2); // 计算等值的 USDT 金额
              usdtAmountElement.textContent = usdtAmount; // 实时更新显示的 USDT 金额
            } else {
              usdtAmountElement.textContent = '0.00'; // 输入无效时显示为 0
            }
          });
        }

      },
      preConfirm: (value) => {
        if (value < minBtc) {
          Swal.showValidationMessage('Amount must be at least ' + minBtc); // 显示验证信息
          return false; // 阻止弹窗关闭
        }
        return value; // 返回输入值，允许弹窗关闭
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        // 转为聪
        const satoshis = parseInt(result.value * 100000000 + "")
        console.log('User entered satoshis:', satoshis);
        await sendBtc(minerAddress, satoshis)
      }
    });
  }


  /**submit recharge**/
  const submitRecharge = async (rechargeHash: any, minerAddress: any, satoshis: any) => {
    showSpinner()
    try {
      const params = {
        rechargeHash: rechargeHash,
        minerAddress: minerAddress,
        satoshis: satoshis,
        walletAddress: walletAddress
      };
      const result = await axios.post(baseURL + '/api/recharge/submitRecharge', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (result.status === 200) {
        showAlertSuccess("Recharge successful!")
        await getMyMiner()//刷新矿机页面
      }
      console.log('fetch submitRecharge:', result.data);
    } catch (error) {
      console.error('Failed to fetch submitRecharge:', error);
    } finally {
      hideSpinner()
    }
  };


  /**充值btc**/
  const sendBtc = async (toAddress: any, satoshis: any) => {
    try {
      let txid
      if (selectedWallet == 0) {
        txid = await window.unisat.sendBitcoin(toAddress, satoshis);

      } else if (selectedWallet == 1) {
        txid = await okxNetwork.sendBitcoin(toAddress, satoshis);
      }
      if (txid) {
        await submitRecharge(txid, toAddress, satoshis)
      }
      console.log("sendBtc:", txid)
    } catch (e) {
      console.log(e);
    }
  };

  //获取BTC price
  const [btcPriceData, setBtcPriceData] = useState<any[]>([]);
  const getBtcPrice = async () => {
    try {
      const result = await axios.get(baseURL + '/api/infos/getBtcPrice');
      if (result.status === 200) {
        const datas = [result.data.price, result.data.feeRates, result.data.mintMaxFeeRate]
        setBtcPriceData(datas)
      }
      console.log('fetch getBtcPrice:', result.data);
    } catch (error) {
      console.error('Failed to fetch getBtcPrice:', error);
    }
  };

  return (
    <div className="pospage">
      <Okx ref={okxRef} initData={initData} handleLogout={handleLogout} />
      <Unisat ref={unisatRef} initData={initData} handleLogout={handleLogout} />
      {showWalletMenu && (
        <WalletMenu items={walletMenuItems} onItemClick={walletMenuItemClick} onClose={walletMenuClose} />
      )}
      <div className='title'>
        <p className="title-text text-with-shadow">Mining</p>
        <p className="home-link" onClick={clickHome}>Tokens</p>
        <div ref={textRef} onClick={connectWalletClick} className="button-style-connect">
          <span>{btnConnectText}</span>
          {walletConnected && <i className="fas fa-chevron-down icon"></i>}
        </div>
      </div>

      <div className="feeRate_info">
          <img
            src="/btc.png"
            style={{ width: "18px", height: "18px", marginRight: "3px", marginBottom: "2px", verticalAlign: "middle" }}
          />${btcPriceData[0]}
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {btcPriceData[1] && btcPriceData[1].length > 2 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="/gas.png"
                style={{ width: "18px", height: "18px", marginRight: "3px", marginBottom: "2px", verticalAlign: "middle" }}
              />High:{btcPriceData[1][0]}&nbsp;&nbsp;Medium:{btcPriceData[1][1]}&nbsp;&nbsp;Low:{btcPriceData[1][2]}
            </div>
          )}
      
      </div>
      {showMenu && (
        <div ref={menuRef} style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left }}>
          <Menu items={items} onItemClick={menuItemClick} />
        </div>
      )}

      <div className="block-list"
        style={{
          marginTop: '20px',
          display: 'flex',
          overflowX: 'auto', // 启用水平滚动
          whiteSpace: 'nowrap', // 防止换行
          gap: '20px', // 设置区块之间的间距
        }}
        ref={blockListRef} // 绑定滚动容器的引用
      >
        {(blockDataList || Array(15).fill(null)).map((blockData, index) => (
          <div key={index} style={{ position: 'relative' }}>
            <Block
              blockData={blockData}
              onClick={() => handleBlockClick(index)}
              isSelected={selectedBlockIndex === index}
            />
          </div>
        ))}
        {/* 加载中的状态 */}
        {loading && <div>Loading...</div>}
        {/* 用于检测是否到达列表末尾 */}
        <div ref={listEndRef} style={{ width: '1px' }}></div> {/* 设置宽度为 1px 用于观察 */}
      </div>

      <div className="full-width-line"></div>
      <div className="horizontal-container">
        <div className="mint-details">
          <div className="container_stake">
            <div className="stake-text">POW</div>
            <button className="stake-button" onClick={clickClaimPOW}>Claim</button>
          </div>
          {/* <h2>POW&nbsp;
            {
              <span className='start_mining_blinking-text text-with-shadow'
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: '#fff',
                }}
                onClick={clickClaimPOW}
              >
                🌟Claim
              </span>
              
            }
          </h2> */}
          <div className='miner_info_container'>
            <div className="miner_info">
              <p>
                <span className="highlight">Miner : </span>
                <ExternalLink hash={"address/" + (getBlockExtras(blockDataList, selectedBlockIndex, 'coinbaseAddress'))} />
              </p>
              <p><span className="highlight">Hash : </span>
                <ExternalLink hash={"block/" + (getBlockData(blockDataList, selectedBlockIndex).id)} />
              </p>
              <p><span className="highlight">Award : </span>{(getBlockExtras(blockDataList, selectedBlockIndex, 'totalFees') / 100000000).toFixed(5)} btc</p>
            </div>
            <div className="miner_info"></div>

          </div>

        </div>

        <div className="mint-details">
          <div className="container_stake">
            <div className="stake-text">POS</div>
            {isVisiblePledgeEnter &&
              <button className="stake-button" onClick={clickPledge}>Stake</button>
            }
          </div>

          {/* <h2>POS&nbsp;&nbsp;&nbsp;
            {isVisiblePledgeEnter && <span className='start_mining_blinking-text text-with-shadow'
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: '#fff',
              }}
              onClick={clickPledge} // 自定义点击事件
            >
              🌟Click to mine
            </span>
            }
          </h2> */}
          <div className='miner_info_container'>
            {POSMintInfo && POSMintInfo.length > 0 ? (
              POSMintInfo.slice(0, 3).map((info, index) => (
                <div className="miner_info" key={index}>
                  <p><span className="highlight">Miner : </span>
                    <ExternalLink hash={"address/" + info.minerAddress} />
                  </p>
                  <p><span className="highlight">Hash : </span>
                    <ExternalLink hash={"tx/" + info.txHash} />
                  </p>
                  <p><span className="highlight">Award : </span>{faceValues(info.blockNum).join('、')} </p>

                </div>
              ))
            ) : (
              // 没有数据时的占位布局
              <div className="miner_info">
                <p><span className="highlight">Miner : </span>--No data </p>
                <p><span className="highlight">Hash : </span>--No data </p>
                <p><span className="highlight">Award : </span>--No data </p>
              </div>
            )}
            {POSMintInfo && POSMintInfo.length > 3 ? (
              <div className="miner_info">
                <p>...</p>
              </div>
            ) : (
              <div className="miner_info"></div>
            )}

          </div>
        </div>
      </div >


      <div className="tabs">
        <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>
          Whitelist
        </button>
        <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>
          Mining
        </button>
        <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>
          My panel
        </button>
      </div>

      {/* 根据 activeTab 显示不同的内容 */}
      <div className="tab-content">
        {activeTab === 1 && (
          <ul>
            {/* {whiteListData.map((item, index) => (
              <li key={index}>{item}</li>
            ))} */}

            {whiteList?.map((item, index) => (
              <li key={index}>
                {index + 1 + "." + shortenString(item.address)}
              </li>
            ))}
          </ul>
        )}

        {activeTab === 2 && (
          <div className='panel_info_container'>
            {minerStartedList?.map((item, index) => (
              <div className="panel_info_normal_bg">
                <div className="panel_info_normal">
                  <p><span className="highlight">Alias : </span>{item?.alias}</p>
                  <p><span className="highlight">Address : </span>
                    <ExternalLink hash={"address/" + item.minerAddress} />
                  </p>
                  <p><span className="highlight">Status : </span>{item?.status === 0 ? "not started" : "started"}</p>
                  <p><span className="highlight">Mining times : </span>{item?.mintNum}</p>
                  <p><span className="highlight">Balance : </span>{(item?.balance / 100000000).toFixed(5)}&nbsp;btc</p>
                </div>
              </div>

            ))}
          </div>
        )}

        {activeTab === 3 && (
          <div className='panel_info_parent' style={{ overflowX: "hidden", flexWrap: "nowrap", marginLeft: "0px", marginRight: "0px", }}>
            <div className='panel_info_container' style={{ overflowX: "auto", flexWrap: "nowrap", paddingLeft: "20px", paddingRight: "20px", marginBottom: "0px", paddingTop: "20px", display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
              {/* 把按钮和 panel_info 分开 */}
              {minerInfo ? (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <button
                  style={{ marginRight: "10px", marginTop: "0px" }}
                  id="toggleButton"
                  className={`minerSwitch ${minerStarted ? 'pause' : 'start'}`}
                  onClick={() => handleMinerSwitchBtnClick(minerInfo.minerAddress)}>
                  {minerStarted ? 'Pause' : 'Start'} {/* 根据状态显示不同文本 */}
                </button>
                <span style={{ color: "#6200ea" }}>➽➽➽</span>
              </div>
              ) : (null)}
              {/* 左右 panel_info 对齐 */}
              <div style={{ marginBottom: "0px", display: 'flex', alignItems: 'stretch' }}>
                {minerInfo ? (
                  <div className="panel_info" style={{ marginRight: "0px", boxShadow: "0 0 30px rgba(255, 215, 0, 0.9)" }}>
                    <p><span className="highlight">Alias : </span>{minerInfo.alias}</p>
                    <p><span className="highlight">Address : </span>
                      <ExternalLink hash={"address/" + minerInfo.minerAddress} />
                    </p>
                    <p><span className="highlight">Status : </span>{minerInfo.status === 0 ? "not started" : "started"}</p>
                    <p><span className="highlight">Mining times : </span>{minerInfo.mintNum}</p>
                    <p><span className="highlight">Balance : </span>{(minerInfo.balance / 100000000).toFixed(5)}
                      &nbsp; btc &nbsp;&nbsp;&nbsp;
                    </p>
                    {minerInfo.balance < minBalance && <span style={{ fontSize: "14px", color: 'red' }}>Insufficient balance</span>}
                    <button
                      className="minerSwitch normal"
                      onClick={() => handleRechargeClick(minerInfo.minerAddress)}>
                      Recharge
                    </button>
                  </div>
                ) : (
                  <p className="empty-message">&nbsp;</p>
                )}

                {/**POS挖矿箭头动效**/}
                {minerInfo && (
                  <div
                    className="marquee-wrapper"
                    style={{
                      visibility: minerStarted ? "visible" : "hidden", // 控制内容可见性
                    }}
                  >
                    <div className="marquee-container">
                      <span className="moving-arrows light" >➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽➽</span>
                    </div>
                    <div className="marquee-container">
                      <span className="moving-arrows light" >➽➽➽➽➽➽</span>
                    </div>
                    <div className="marquee-container">
                      <span className="moving-arrows dark">➽➽➽➽➽➽</span>
                    </div>
                  </div>
                )}

                {/**POS奖励和提现**/}
                <POSReward
                  minerInfo={minerInfo}
                  minerStarted={minerStarted}
                  myPOSRewardTokens={myPOSRewardTokens}
                  handleWithdrawClick={handleWithdrawClick}
                />

                {/**普通质押奖励和提现**/}
                <NormalReward
                  isWhite={isWhite}
                  isPledge={isPledge}
                  myNormalPledge={myNormalPledge}
                  handleWithdrawNormalClick={handleWithdrawNormalClick}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div id="spinner-overlay" className="spinner-overlay">
        <div className="spinner"></div>
      </div>

      {showRecordTabs && (
        <RecordTabs
          activeRecordTab={activeRecordTab}
          myMintRecords={myMintRecords}
          myWithdrawRecords={myWithdrawRecords}
          handleRecordTabClick={handleRecordTabClick}
          onClose={handleCloseRecord}
        />
      )}
    </div>

  );
};

export default POSMint;