import React from 'react';
import { MintInfo } from '../model/MintInfo';
import { Pledge } from '../model/Pledge';
import { tokenNames } from '../cons/constant';


interface NormalRewardProps {
  isWhite: any
  isPledge: any;  // 可根据实际类型定义
  myNormalPledge: Pledge | null | undefined;
  handleWithdrawNormalClick: () => void;
}

/**根据质押日期计算是否可提现**/
function canWithdraw(myNormalPledge: Pledge) {
  const endDate = myNormalPledge.endDate
  const now = new Date();
  return now >= new Date(endDate)
}

const POSReward: React.FC<NormalRewardProps> = ({ isWhite, isPledge, myNormalPledge, handleWithdrawNormalClick }) => {
  if (isWhite || !isPledge || !myNormalPledge) return null;

  return (
    <div className="panel_info" style={{ alignItems: "center", marginLeft: "0px", position: "relative",height:"350px", width: "200px" }}>
      {Array.from({ length: Math.floor(Math.random() * 41) + 10 }).map((_, index) => {
        const size = Math.random() * 6 + 4;
        const randomLeft = Math.random() * (200 - size) + 15;
        const randomBottom = Math.random() * 100;
        const randomDuration = Math.random() * 3 + 2;
        const randomDelay = Math.random() * 2;

        return (
          <div
            key={index}
            className="bubble_small"
            style={{
              width: `${size}px`,
              height: `${size}px`,
              left: `${randomLeft}px`,
              bottom: `${randomBottom}%`,
              animationDuration: `${randomDuration}s`,
              animationDelay: `${randomDelay}s`,
              zIndex: 1,
              position: "absolute",
            }}
          ></div>
        );
      })}

      <div className="token-images" style={{ height: "100%", marginRight: "20px" }}>
        {myNormalPledge && (
          (() => {
            const index = 1
            const columns = 4;
            const rows = 2;
            const gridCellWidth = 200 / columns;
            const gridCellHeight = 320 / rows;

            const row = Math.floor(index / columns);
            const col = index % columns;

            const offsetX = Math.random() * (gridCellWidth * 0.3);
            const offsetY = Math.random() * (gridCellHeight * 1);
            // 根据id获取对应的tokenName
            const tokenName = tokenNames[myNormalPledge.pledgeTokenID as keyof typeof tokenNames] || "default";
            return (
              <img
                src={`/parvalue/${tokenName}.png`}
                alt={tokenName}
                className="floating-image"
                style={{
                  position: 'absolute',
                  top: `${row * gridCellHeight + offsetY}px`,
                  left: `${col * gridCellWidth + offsetX}px`,
                  animationDelay: `${Math.random() * 1}s`,
                  filter: 'drop-shadow(0 0 10px rgba(255, 215, 0, 0.9))',
                  opacity: 0,
                  transform: 'scale(0)',
                  transition: 'opacity 8s ease-out, transform 8s ease-out',
                }}
                onLoad={(e) => {
                  e.currentTarget.style.opacity = (1).toString();
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              />
            );
          })()
        )}
      </div>

      <button
        className="minerSwitch normal"
        style={{ position: "absolute",marginLeft:"10px", bottom: "15px",zIndex: 10, width: "auto" }}
        onClick={handleWithdrawNormalClick}
        disabled={!canWithdraw(myNormalPledge)}>
        {canWithdraw(myNormalPledge) ? "Withdraw" : new Date(myNormalPledge.endDate).toLocaleString()}
      </button>
    </div>
  );
};

export default POSReward;