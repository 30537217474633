// MyPieChart.tsx
import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

const MyPieChart: React.FC = () => {
    useEffect(() => {
        const canvas = document.getElementById('myPieChart') as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                const config = {
                    type: 'pie' as const,
                    data: {
                        labels: ['51%', '20%', '15%', '8%', '6%'],
                        datasets: [{
                            data: [51, 20, 15, 8, 6],
                            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
                            borderColor: '#ea9d44',
                            borderWidth: 2,

                        }]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                    }
                };

                new Chart(ctx, config);
            }
        } else {
            console.error('Canvas element not found');
        }
    }, []);

    return (
        <div className="chart-container">
            <canvas id="myPieChart"></canvas>
        </div>
    );
};

export default MyPieChart;