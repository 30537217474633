import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './homepage/Homepage';
import POWGet from './pow_get/POWGet';
import POSMint from './pos_mint/POSMint';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<POSMint />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/claim" element={<POWGet />} />
      </Routes>
    </Router>
  );
};

export default App;