import { tokenNames } from './cons/constant';


function shortenString(str) {
    if (str) {
        if (str.length <= 10) {
            return str; // 如果字符串长度小于等于10，直接返回原字符串
        }
        return str.slice(0, 5) + '...' + str.slice(-5);
    }
    return ""
}

function formatNumber(num) {
    if (Math.abs(num) >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B'; // Billion
    } else if (Math.abs(num) >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M'; // Million
    } else if (Math.abs(num) >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K'; // Thousand
    } else {
        return num.toString(); // Less than 1000
    }
}
/**
 * 计算时间戳与当前时间的差异，并根据不同时间范围显示天、小时、分钟或秒
 * **/
function timeDifference(timestamp) {
    const currentTime = Math.floor(Date.now() / 1000); // 当前时间的时间戳（秒）
    const difference = currentTime - timestamp; // 差异（秒）

    const secondsInMinute = 60;
    const secondsInHour = 60 * 60;
    const secondsInDay = 24 * 60 * 60;

    if (difference <= 0) {
        return "now"; // 差值小于等于 0 时显示“现在”
    } else if (difference >= secondsInDay) {
        const days = Math.floor(difference / secondsInDay);
        return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (difference >= secondsInHour) {
        const hours = Math.floor(difference / secondsInHour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (difference >= secondsInMinute) {
        const minutes = Math.floor(difference / secondsInMinute);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
        return `${difference} second${difference > 1 ? 's' : ''} ago`;
    }
}

/**使用 replace 方法去除所有空格**/
const getBaseName = (tokenName) => {
    return tokenName.replace(/\s+/g, '')
};

/**
 * 格式化时间
 * **/
function formattedDate(isoDate) {
    const date = new Date(isoDate);

    // 提取各部分
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // 格式化输出
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate
}


function faceValues(blockNum) {
    const lastDigit = blockNum % 10; // 获取 blockNum 的最后一位数字
    // 筛选符合条件的完整 tokenName
    const matchingTokens = Object.values(tokenNames)
        .filter((tokenName) => parseInt(tokenName[1], 10) === lastDigit);
    // console.log(matchingTokens);
    return matchingTokens
}

export { shortenString, formattedDate, formatNumber, timeDifference, faceValues };