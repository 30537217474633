// src/bubble/Bubble.tsx
import React, { useEffect } from 'react';
import './bubble.css';

const Bubble = () => {
  useEffect(() => {
     // 如果有需要，可以添加更多动画或初始化的 JavaScript 代码
  }, []);

  return (
    <div className="bubble-container">
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/10MM.png`} alt="Bubble 1" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/11MM.png`} alt="Bubble 2" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/15MM.png`} alt="Bubble 3" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/16MM.png`} alt="Bubble 4" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/18MM.png`} alt="Bubble 5" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/19MM.png`} alt="Bubble 6" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/20MM.png`} alt="Bubble 7" />
    </div>
    <div className="bubble">
      <img src={`${process.env.PUBLIC_URL}/parvalue/21MM.png`} alt="Bubble 8" />
    </div>
  </div>
  );
};

export default Bubble;