import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import TypewriterText from '../components/TypewriterText';
import '../bubble/bubble.css';
import Bubble from '../bubble/bubble';
import MyPieChart from '../MyPieChart'; // 确保路径正确


const welcome = "Welcome to the Bitcoin ParValue! As the world's first cryptocurrency, Bitcoin has completely changed the way people exchange value. \nWhether you are a seasoned investor or just starting to understand blockchain technology, Bitcoin provides you with a free, secure, decentralized currency system."



const Homepage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      // 添加点击事件来控制播放和暂停
      const togglePlayPause = () => {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      };

      video.addEventListener('click', togglePlayPause);

      // 清除事件监听器以防内存泄漏
      return () => {
        video.removeEventListener('click', togglePlayPause);
      };
    }
  }, []);
  // const video = document.getElementById("myVideo") as HTMLVideoElement;
  // if (video) {
  //   video.addEventListener("click", () => {
  //     if (video.paused) {
  //       video.play();
  //     } else {
  //       video.pause();
  //     }
  //   });
  // }



  return (
    <div className="homepage">
      <div className='title'>
        <p className="title-text text-with-shadow">ParValue</p>
      </div>
      {/* <button onClick={handleEnterMint}>Enter Mint</button> */}
      <div className='video-container'>
        <video className="video-element" ref={videoRef} autoPlay muted loop playsInline>{/*加上controls属性显示菜单*/}
          <source src="/parvalue/528_1730876858.mp4" type="video/mp4" />
        </video>
      </div>

      <TypewriterText />


      <div className='header_container'>
        <text className='header'>EXPLORE THE WORLD OF BITCOIN - THE BORDERLESS, DECENTRALIZED DIGITAL ASSET!</text>
        <text className='line'></text>
        <text className='normal_tx' style={{ whiteSpace: "pre-line" }}>{welcome}
        </text>

        <text className='header'>ABOUT BITCOIN PARVALUE</text>
        <text className='line'></text>
        <div className='home_container'>
          <div className="left-content">
            {/* <img src={`${process.env.PUBLIC_URL}/parvalue/miner.png`} alt="Logo" /> */}
            <Bubble />

          </div>
          <div className='right-content'>
            <text className='right_title'>
              🌟10MM,11MM,15MM,16MM,18MM,19MM,20MM,21MM
            </text>
            <text className="miner_tx">
              <p><span className="highlight">Deployment:</span> ParValue are deployed in Brc-20 4 characters, Brc-20 5 characters, and Src-20.</p>
              <p><span className="highlight">Total supply:</span> There are 8 groups of Bitcoin denominations, deployed on three tracks, with a total of 24 landmarks, and a total circulation of 15260000000 coins.</p>
              <p><span className="highlight">Partner mines: </span> Russian mines and Hong Kong mines.</p>
              <p><span className="highlight">The meaning of MM:  </span> financial unit million (million), length unit mm (mm).</p>
              <p><span className="highlight">How to buy:   </span> Make a purchase transaction in the okx web3 wallet inscription area.</p>

            </text>
          </div>
        </div>

        <div className='home_container'>
          <div className='right-content'>
            <text className="miner_tx">
              <text className='right_title'>
                <p>🌟The ParValue system is the best solution to solve the 51 attack. It aims to solve the potential risks of the 51 attack by dispersing computing power. The ParValue system uses the consensus of the Bitcoin community to raise funds for Bitcoin miners to maintain the Bitcoin ecosystem network security.</p>
              </text>
              <p><span className="highlight">Miner rewards:</span>51%</p>
              <p><span className="highlight">Whitelist holder:</span>20%</p>
              <p><span className="highlight">Operations:</span>15%</p>
              <p><span className="highlight">Founding team:</span>8%</p>
              <p><span className="highlight">Community rewards:</span>6%</p>

            </text>
          </div>

          <div className="left-content">
            <MyPieChart />
          </div>
        </div>

        <text className='header'>PARVALUE ECOLOGY
        </text>
        <text className='line'></text>
        <div className='home_container'>
          <div className='right-content'>
            <text className="miner_tx">
              <text className='right_title'>
                🌟ParValue System
              </text>
              <p><span className="highlight">
                1.Miner salary payment and computing power decentralization(protecting the security of Bitcoin)===&gt;
                <a href="/mining" target="_blank" style={{ textDecoration: 'underline', color: 'var(--shadow-color—Purple)' }}>
                  POS Mining
                </a>
              </span></p>
              <p>The ParValue system effectively disperses computing power and helps prevent 51% attacks through an innovative miner salary payment mechanism. We provide an intelligent salary payment platform for miners, dispersing computing power to different participants around the world, ensuring the decentralization and security of the Bitcoin network. Through this feature, miners can not only obtain more stable and fair rewards, but also provide strong support for the long-term security of Bitcoin.</p>

              <p><span className="highlight">
                2.Bitcoin cross-protocol bridge(improving the liquidity of the Bitcoin ecosystem)===&gt;
                <a href="https://btc10.io" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline', color: 'var(--shadow-color—Purple)' }}>
                  btc10.io
                </a>
              </span></p>
              <p>As one of the important infrastructures of the Bitcoin ecosystem, the cross-protocol bridge of Bitcoin breaks the barriers between other protocols within the Bitcoin chain and solves the liquidity problem of Bitcoin in decentralized finance (DeFi). Through cross-chain bridging technology, we have introduced an efficient currency exchange channel into the Bitcoin ecosystem, allowing users to easily convert between different protocol assets, further promoting the in-depth integration of Bitcoin with the global cryptocurrency market.</p>


              <p><span className="highlight">
                3.Bitcoin ecological wallet(optimize UTXO management and improve efficiency)===&gt;
                <a style={{ color: 'var(--shadow-color—Purple)' }}>
                  Coming soon
                </a>
              </span></p>
              <p>The Bitcoin ecological wallet is not just an ordinary Bitcoin wallet, it solves the Bitcoin UTXO (Unspent Transaction Output) occupation problem through advanced technology. Our wallet system can effectively manage users' UTXO, making transactions more efficient and stable, while improving the overall performance and security of the network. Users can easily manage their assets in the Bitcoin ecological wallet and enjoy the convenience and security brought by the Bitcoin network.</p>

              <text className="right_title" style={{ marginTop: '80px', display: 'block' }}>
                🌟Injecting new vitality into the Bitcoin ecosystem
              </text>
              <p>The ParValue is not only a technical solution, but also an innovative idea. We will continue to promote the healthy development of the Bitcoin ecosystem and help Bitcoin realize more application scenarios around the world through decentralized, safe, and efficient technical design. Whether you are a miner, developer or ordinary user, you can participate in the future construction of the Bitcoin ecosystem through the Bitcoin face value system.</p>
              Join us to explore the infinite possibilities of Bitcoin and open a new chapter in digital assets!

            </text>
          </div>
        </div>


        <text className='header'>VALUE-ADDED POTENTIAL
        </text>
        <text className='line'></text>
        <div className='home_container'>
          <div className='right-content'>
            <text className="miner_tx">
              <text className='right_title'>
                <p>🌟ParValue’s value-added potential – a vision from US$20 million to US$300 billion</p>
              </text>
              <p>In the wave of rapid development of the global cryptocurrency market, ParValue is becoming a force that cannot be ignored in the Bitcoin ecosystem. Currently, we have a market cap of $20 million, but this is just the beginning of our journey. Through innovative technical solutions, global market layout and extensive ecological cooperation, ParValue has huge potential to jump from a market value of US$20 million to US$300 billion.</p>

              <text className='right_title' style={{ marginTop: '80px', display: 'block' }}>
                <p>🌟Key drivers of future market capitalization growth</p>
              </text>

              <p><span className="highlight">
                1.The Guardian of Bitcoin Security and Decentralization
              </span>
              </p>
              ParValue's unique miner salary payment system solves the problem of computing power concentration in the Bitcoin network, effectively prevents the risk of 51% attacks, and enhances the security of the Bitcoin network. With widespread adoption by miners around the world, this feature will drive more users and miners to flock to the ParValue platform, driving rapid growth in market demand.


              <p><span className="highlight">
                2.Solve liquidity bottlenecks and connect Bitcoin to the DeFi world
              </span>
              </p>
              Bitcoin’s cross-protocol bridge connects Bitcoin to other blockchain networks, greatly increasing Bitcoin’s liquidity. With the rapid expansion of DeFi and cross-chain ecology, more funds and users will be introduced to ParValue's cross-chain bridge platform, driving the rapid increase in transaction volume and asset management scale.

              <p><span className="highlight">
                3.Efficient UTXO management improves Bitcoin ecological efficiency
              </span>
              </p>
              By optimizing UTXO management, Bitcoin Wallet improves the transaction efficiency and stability of the Bitcoin network, attracting more users to choose ParValue as their main wallet tool. As Bitcoin usage scenarios continue to expand, this technological innovation will drive more transaction activities and further enhance ParValue's market influence.


              <p><span className="highlight">
                4.Bitcoin’s status as global digital gold
              </span>
              </p>
              Bitcoin has a limited supply and its long-term scarcity will continue to drive up its market value. As an important part of the Bitcoin ecosystem, ParValue will directly benefit from the overall value growth of Bitcoin. As Bitcoin is widely adopted by more and more institutional investors, businesses and individual users around the world, ParValue's market capitalization will grow in tandem with the rise in Bitcoin price and demand.

              <text className='right_title' style={{ marginTop: '80px', display: 'block' }}>
                <p>🌟The road to growth from 20 million to 300 billion</p>
              </text>
              <p>The current market value of ParValue is US$20 million, but with the implementation of technology and market expansion, we are confident that the market value will increase hundreds of times. In the next few years, the Bitcoin ecosystem will usher in a wider range of application scenarios, including payments, decentralized finance, smart contracts and cross-chain asset flows. ParValue will become the core driving force in these scenarios, helping to push the entire Bitcoin ecosystem into the next growth cycle.</p>
              By solving the existing pain points of the Bitcoin network and building a more secure, efficient, and decentralized Bitcoin ecosystem, ParValue is expected to achieve a huge leap from a market value of US$20 million to US$300 billion in the future.


              <text className='right_title' style={{ marginTop: '80px', display: 'block' }}>
                <p>🌟Invite you to witness the future together</p>

              </text>
              <span className="highlight">
                ParValue's path to growth is filled with opportunities. Whether you are an investor, miner or developer of the Bitcoin ecosystem, join us now and you will have the opportunity to participate in this great change. Witness ParValue’s leapfrog growth from US$20 million to US$300 billion and create a new chapter in the future of Bitcoin
              </span>

            </text>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Homepage;