import React from 'react';
import './Menu.css';

const Menu = ({ items, onItemClick }) => {
  return (
    <div className="menu">
      {items.map((item, index) => (
        <div key={index} className="menu-item" onClick={() => onItemClick(item,index)}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default Menu;