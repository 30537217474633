import React, { createContext, useState, Dispatch, SetStateAction, ReactNode } from 'react';

interface WalletContextType {
    walletAddress: string | null;
    setWalletAddress: Dispatch<SetStateAction<string | null>>;
    walletConnected: boolean;
    setWalletConnected: Dispatch<SetStateAction<boolean>>;
    btnConnectText: string;
    setBtnConnectText: Dispatch<SetStateAction<string>>;

}
interface WalletProviderProps {
    children: ReactNode;
  }

const defaultContext: WalletContextType = {
    walletAddress: null,
    setWalletAddress: () => { },
    walletConnected: false,
    setWalletConnected: () => { },
    btnConnectText: 'Connect',
    setBtnConnectText: () => { },

};
export const WalletContext = createContext<WalletContextType>(defaultContext);

export const WalletProvider: React.FC<WalletProviderProps> = ({ children }) => {
    const [walletAddress, setWalletAddress] = useState<string | null>(null);
    const [walletConnected, setWalletConnected] = useState<boolean>(false);
    const [btnConnectText, setBtnConnectText] = useState<string>('Connect');


    return (
        <WalletContext.Provider value={{
            walletAddress,
            setWalletAddress,
            walletConnected,
            setWalletConnected,
            btnConnectText,
            setBtnConnectText,
        }}>
            {children}
        </WalletContext.Provider>
    );
};